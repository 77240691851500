const getParsedMappingFromFieldValue = (valuesToSelect, selectedValue) => {
    if (typeof selectedValue === 'string') {
        const isInHeaders = valuesToSelect.indexOf(selectedValue) !== -1;
        if (isInHeaders) {
            return [selectedValue];
        }
        return [];
    }
    return selectedValue;
};

export default getParsedMappingFromFieldValue;
