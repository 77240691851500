import firebase from '../../firebase';
import getCurrentUserId from './getCurrentUserId';

const db = firebase.firestore();

function batchSplit(ref, data, batchAction) {
    if (data.length <= 500) {
        return batchAction(ref, data);
    }
    const first500 = data.slice(0, 500);
    const rest = data.slice(500, data.length);
    return Promise.all([
        batchSplit(ref, first500, batchAction),
        batchSplit(ref, rest, batchAction),
    ]);
}

function batchAdd(collectionPath, data) {
    const batch = db.batch();
    data.forEach((item) => {
        const docRef = db
            .collection(collectionPath)
            .doc();

        batch.set(docRef, item);
    });
    return batch.commit();
}

const importData = async (data) => {
    const uid = getCurrentUserId();
    const importId = db.collection(`users/${uid}/imports`).doc().id;
    if (uid) {
        return batchSplit(
            `users/${uid}/imports/${importId}/rides`,
            data,
            batchAdd,
        );
    }
    return undefined;
};

export default importData;
