export default function find(predicate, arr = []) {
    if (typeof predicate !== 'function') {
        throw new TypeError('firt argument must be a function');
    }
    if (!Array.isArray(arr)) {
        throw new TypeError('second argument must be an array');
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
        if (i in arr) { // skip holes
            const elem = arr[i];
            if (predicate(elem, i, arr)) {
                return elem;
            }
        }
    }
    return undefined;
}
