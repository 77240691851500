import styled from 'styled-components';
import { ButtonIcon } from 'react-rainbow-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Content = styled.div`
    display: flex;
`;

export const Value = styled.h1`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-transform: capitalize;
`;

export const OptionsContainer = styled.div`
    width: 170px;
`;

export const IconContainer = styled.span`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ArrowButtonIcon = styled(ButtonIcon)`
    flex-shrink: 0;

    > svg {
        width: 12px !important;
        height: 6px !important;
    }
`;
