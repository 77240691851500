import React, { useState } from 'react';
import { Button } from 'react-rainbow-components';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import Header from '../../../components/Header';
import ArrowBack from '../../../components/icons/ArrowBack';
import {
    Container,
    Title,
    BackButton,
} from './styled';
import MappingTable from './mappingTable';
import getMappingTableData from '../../../services/helpers/getMappingTableData';
import useFileData from '../../../hooks/useFileData';
import useLocalMapping from '../../../hooks/useLocalMapping';
import getUpdatedMapping from './helpers/getUpdatedMapping';

function FieldMapping() {
    const { groupId } = useParams();
    const [showErrors, setShowErrors] = useState(false);
    const csvFile = useFileData();
    const [mapping, setMapping] = useLocalMapping(groupId, csvFile);
    const hasCsvFileLoaded = Array.isArray(csvFile) && csvFile.length > 0;
    const history = useHistory();
    const tableData = getMappingTableData(mapping, showErrors);

    const onChangeField = ({ fromFields, toField }) => {
        if (toField) {
            const updatedMapping = getUpdatedMapping({
                mapping,
                toField,
                fromFields,
            });
            setMapping(updatedMapping);
        }
    };

    const handleChangeFieldType = (fieldData, option) => {
        const updatedMapping = getUpdatedMapping({
            mapping,
            toField: fieldData.name,
            fromFields: [],
            typeSelected: option.name,
        });
        setMapping(updatedMapping);
    };

    const onStartImport = () => {
        setShowErrors(true);
        const hasErrors = getMappingTableData(mapping, true).some((item) => !!item.error);
        if (!hasErrors) {
            history.push(`/app/${groupId}/import/serviceMapping`);
        }
    };

    if (hasCsvFileLoaded) {
        return (
            <Container>
                <Header
                    className="rainbow-m-horizontal_small"
                    leftContent={(
                        <>
                            <BackButton data-cy="back-button" to={`/app/${groupId}/import`}>
                                <ArrowBack />
                            </BackButton>
                            <Title>Connect Rides</Title>
                        </>
                    )}
                    rightContent={(
                        <Button
                            label="Start Import"
                            variant="brand"
                            shaded
                            onClick={onStartImport}
                            id="import-button"
                        />
                    )}
                />
                <MappingTable
                    tableData={tableData}
                    onChangeField={onChangeField}
                    onChangeFieldType={handleChangeFieldType}
                />
            </Container>
        );
    }
    return <Redirect to="/" />;
}

export default FieldMapping;
