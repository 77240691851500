import React, { useState, useEffect } from 'react';
import {
    useHistory, useParams,
} from 'react-router-dom';
import {
    Button,
    Table,
    Column,
    HelpText,
} from 'react-rainbow-components';
import Header from '../../../components/Header';
import ArrowBack from '../../../components/icons/ArrowBack';
import useFileData from '../../../hooks/useFileData';
import useLocalMapping from '../../../hooks/useLocalMapping';
import isSingleService from '../../../services/helpers/isSingleService';
import AssignServiceSelector from './components/AssignServiceSelector';
import {
    Container,
    Title,
    BackButton,
    SectionContainer,
    SectionHeader,
    SectionHeaderTitle,
    SectionHeaderTitleBold,
    SectionHeaderSubTitle,
    SectionHeaderSubTitleBold,
} from './styled';

const getSourceValue = (row, fromFields) => fromFields.reduce((acc, field) => {
    const value = row[field];
    if (acc) {
        if (value) {
            return `${acc} - ${value}`;
        }
        return acc;
    }
    return value;
}, '');

const getSourceServices = (mapping, fileData) => {
    if (Array.isArray(fileData)) {
        return fileData.reduce((acc, currentRow) => {
            if (mapping) {
                if (typeof mapping.serviceLevel.fromFields === 'string') {
                    const fromField = mapping.serviceLevel.fromFields;
                    const sourceValue = currentRow[fromField];
                    if (acc.includes(sourceValue) || sourceValue === undefined) {
                        return acc;
                    }
                    return [
                        ...acc,
                        sourceValue,
                    ];
                }
                const sourceValue = getSourceValue(currentRow, mapping.serviceLevel.fromFields);
                if (acc.includes(sourceValue) || sourceValue === undefined) {
                    return acc;
                }
                return [
                    ...acc,
                    sourceValue,
                ];
            }
            return [];
        }, []);
    }
    return [];
};

function getError(showErrors, toService) {
    if (showErrors && !toService) {
        return 'All services need to be mapped';
    }
    return undefined;
}

const getServiceMappingTable = ({ mapping, sourceServices, showErrors }) => {
    if (mapping && typeof mapping === 'object') {
        const serviceMapping = mapping.serviceLevel && mapping.serviceLevel.mapping;
        if (serviceMapping && typeof serviceMapping === 'object') {
            return Object.keys(serviceMapping)
                .sort()
                .filter((key) => sourceServices.indexOf(key) >= 0)
                .map((fromService, index) => {
                    const toService = serviceMapping[fromService]
                        && serviceMapping[fromService].label;
                    const key = `service_map_${index}`;
                    return {
                        key,
                        fromService,
                        toService,
                        error: getError(showErrors, toService),
                    };
                });
        }
    }
    return [];
};

const getUpdatedMapping = (mapping, sourceServices) => {
    if (mapping && Array.isArray(sourceServices)) {
        const mappingResult = {
            ...mapping,
        };
        return sourceServices.reduce((acc, sourceKey) => {
            const hasMapping = acc.serviceLevel && acc.serviceLevel.mapping;
            const hasEmptyMapping = hasMapping && !acc.serviceLevel.mapping[sourceKey];
            if (hasEmptyMapping) {
                acc.serviceLevel.mapping[sourceKey] = null;
            }
            if (!hasMapping) {
                acc.serviceLevel = {
                    ...acc.serviceLevel,
                    mapping: {
                        [sourceKey]: null,
                    },
                };
            }
            return acc;
        }, mappingResult);
    }
    return null;
};

export default function ServiceLevelMapping() {
    const history = useHistory();
    const { groupId } = useParams();
    const csvFile = useFileData();
    const fileData = Array.isArray(csvFile) && csvFile[0];
    const [mapping, setMapping] = useLocalMapping(groupId, csvFile);
    const [showErrors, setShowErrors] = useState(false);
    const sourceServices = getSourceServices(mapping, fileData);
    const serviceMappingTable = getServiceMappingTable({ mapping, sourceServices, showErrors });

    useEffect(() => {
        if (isSingleService(mapping)) {
            history.push(`/app/${groupId}/import/preview`);
        } else {
            const updatedMapping = getUpdatedMapping(mapping, sourceServices);
            setMapping(updatedMapping);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeServiceMap = ({ fromService, toService = {} }) => {
        const newMapping = {
            ...mapping,
        };
        newMapping.serviceLevel.mapping[fromService] = toService;
        setMapping(newMapping);
    };

    const handleNext = () => {
        setShowErrors(true);
        const hasErrors = getServiceMappingTable({
            mapping,
            sourceServices,
            showErrors: true,
        }).some((item) => !!item.error);
        if (!hasErrors) {
            history.push(`/app/${groupId}/import/preview`);
        }
    };

    return (
        <Container>
            <Header
                className="rainbow-m-horizontal_small"
                leftContent={(
                    <>
                        <BackButton data-cy="import-preview-back-button" onClick={() => history.goBack()}>
                            <ArrowBack />
                        </BackButton>
                        <Title>Service Level Mapping</Title>
                    </>
                )}
                rightContent={(
                    <>
                        <Button
                            label="Next"
                            variant="brand"
                            shaded
                            onClick={handleNext}
                            id="import-now-button"
                        />
                    </>
                )}
            />
            <SectionContainer>
                <SectionHeader>
                    <HelpText
                        title="Message Title"
                        text={<p>Lorem ipsum dolor sit amet, consectetur adipiscing.</p>}
                    />
                    <SectionHeaderTitle>
                        field:
                        <SectionHeaderTitleBold>service</SectionHeaderTitleBold>
                    </SectionHeaderTitle>
                </SectionHeader>
                <SectionHeaderSubTitle>
                    We find
                    {' '}
                    <SectionHeaderSubTitleBold>
                        {serviceMappingTable.length}
                        {' '}
                        services name
                    </SectionHeaderSubTitleBold>
                    {' '}
                    on your file
                </SectionHeaderSubTitle>
            </SectionContainer>
            <Table
                className="rainbow-m-bottom_large rainbow-m-top_small"
                keyField="key"
                id="validate-table"
                data={serviceMappingTable}
                showRowNumberColumn
            >
                <Column header="found values" field="fromService" />
                <Column
                    header="Evertransit value"
                    field="toService"
                    component={
                        ({ value, row }) => (
                            <AssignServiceSelector
                                value={value}
                                row={row}
                                onChangeServiceMap={onChangeServiceMap}
                            />
                        )
                    }
                />
            </Table>
        </Container>
    );
}
