function getError(field, showErrors) {
    if (showErrors && !field) {
        return 'This field can\'t be empty';
    }
    return undefined;
}

export default function getMappingTableData({ exportFields, mapping, showErrors }) {
    if (Array.isArray(exportFields) && mapping) {
        return exportFields.reduce((acc, value) => {
            const field = mapping[value.evertransitField];
            if (typeof field === 'string') {
                acc.push({
                    ...value,
                    fieldName: field,
                    error: getError(field, showErrors),
                });
            }
            return acc;
        }, []).sort((a, b) => a.order - b.order);
    }
    return [];
}
