import React from 'react';
import PropTypes from 'prop-types';

export default function Location(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="14px" height="20px" viewBox="0 0 14 20">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-import-2" transform="translate(-404.000000, -335.000000)">
                    <g id="Group-23" transform="translate(86.000000, 158.000000)">
                        <g id="Group-12-Copy-3" transform="translate(0.000000, 167.000000)">
                            <g id="Group-14" transform="translate(318.000000, 9.000000)">
                                <g id="address" transform="translate(0.000000, 1.000000)">
                                    <path d="M0,7.00204137 C0,10.8702493 7.00202755,20 7.00202755,20 C7.00202755,20 14.0037951,10.8702493 14.0037951,7.00204137 C14.0037951,3.13539378 10.869044,0 7.00202755,0 C3.13501111,0 0,3.13539378 0,7.00204137" id="Fill-446" fill="#AFAFC3" transform="translate(7.001898, 10.000000) scale(-1, 1) translate(-7.001898, -10.000000) " />
                                    <path d="M2.94117647,6.78342092 C2.94117647,9.03157548 4.75930125,10.8538899 7.00202738,10.8538899 C9.24475351,10.8538899 11.0626186,9.03157548 11.0626186,6.78342092 C11.0626186,4.53578695 9.24475351,2.71347249 7.00202738,2.71347249 C4.75930125,2.71347249 2.94117647,4.53578695 2.94117647,6.78342092" id="Fill-448" fill="#FFFFFF" transform="translate(7.001898, 6.783681) scale(-1, 1) translate(-7.001898, -6.783681) " />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Location.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Location.defaultProps = {
    style: {},
    className: undefined,
};
