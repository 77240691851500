import styled from 'styled-components';
import Error from '../../../../components/icons/Error';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const ErrorMessage = styled(Error)`
    width: 48px;
    height: 48px;
`;

export const Title = styled.h1`
    font-size: 22px;
    letter-spacing: 0.5;
    color: #616175;
    font-weight: 400;
`;

export const BackButton = styled.a`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-right: 20px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;
