import { useState, useEffect } from 'react';
import getExportFields from '../../services/firebase/getExportFields';

export default function useExportFields() {
    const [fields, setFields] = useState([]);
    useEffect(() => {
        (async () => {
            try {
                const response = await getExportFields();
                if (Array.isArray(response)) {
                    setFields(response[0].fields || []);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        })();
    }, []);
    return fields;
}
