import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import Unknown from '../../icons/Unknown';

const colorMap = {
    completed: '#79C9A6',
    pending: '#F59F1E',
    inProgress: '#F59F1E',
    failed: '#FE4849',
};

function getColor(props) {
    return colorMap[props.value] || 'rgb(63, 73, 84)';
}

const backgroundColorMap = {
    completed: '#EDF8F3',
    pending: '#FEF3E4',
    inProgress: '#FEF3E4',
    failed: '#FFECEC',
};

export const Container = styled(Badge)`
    padding-right: 12px;
    padding-left: 8px;
    color: ${getColor};
    background-color: ${(props) => backgroundColorMap[props.value]};
`;

export const Icon = styled(Unknown)`
    width: 13px;
    height: 13px;
    margin-right: 6px;
    margin-bottom: 1px;
    color: ${getColor};
`;

export const Value = styled.h4`
    font-size: 16px;
    color: ${getColor};
    text-transform: capitalize;
`;
