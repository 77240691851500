import useLocalData from '../useLocalData';
import serializeDataToSessionStorage from '../../services/helpers/serializeDataToSessionStorage';

const importStatsSessionStorageKey = 'importStats';

const useImportStats = () => {
    const stats = useLocalData(importStatsSessionStorageKey);
    const setStats = (newStats) => {
        serializeDataToSessionStorage(importStatsSessionStorageKey, newStats);
    };
    return [stats, setStats];
};

export default useImportStats;
