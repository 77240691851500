import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DoneCard from '../../../../components/DoneCard';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const StartButton = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    font-size: 16px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;

export const Card = styled(DoneCard)`
    max-width: 500px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;


export const Divider = styled.span`
    height: 32px;
    width: 1px;
    background-color: ${(props) => props.theme.rainbow.palette.border.divider};
    flex-shrink: 0;
`;

export const Question = styled.h2`
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    text-align: center;
    margin: 24px 0 4px 0;
`;
