import React from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Card,
    CheckmarkIcon,
    Title,
    Description,
    Footer,
} from './styled';

export default function DoneCard(props) {
    const {
        className,
        style,
        title,
        description,
        footer,
    } = props;

    return (
        <Container className={className} style={style}>
            <Card>
                <CheckmarkIcon />
                <Title>{title}</Title>
                <Description>
                    {description}
                </Description>
                <Footer>
                    {footer}
                </Footer>
            </Card>
        </Container>
    );
}


DoneCard.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

DoneCard.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
    description: undefined,
    footer: undefined,
};
