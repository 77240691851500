/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Button,
    Column,
    Option,
    ButtonMenu,
    DatePickerModal,
    ButtonIcon,
    MenuItem,
} from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { showAppMessage } from '@rainbow-modules/app';
import fetchRidesSample from '../../../actions/export/fetchRidesSample';
import find from '../../../services/helpers/find';
import useExportTemplates from '../../../hooks/useExportTemplates';
import useExportFields from '../../../hooks/useExportFields';
import usePersistentState from '../../../hooks/usePersistentState';
import isFilledArray from '../../../services/helpers/isFilledArray';
import Header from '../../../components/Header';
import InlineEditField from '../../../components/InlineEditField';
import Plus from '../../../components/icons/Plus';
import getMappingTableData from './helpers/getMappingTableData';
import getExtraExportFields from './helpers/getExtraExportFields';
import getDateValue from './helpers/getDateValue';
import getFormattedRangeDate from './helpers/getFormattedRangeDate';
import getDatePickerTitle from './helpers/getDatePickerTitle';
import isValidDateRangeOption from './helpers/isValidDateRangeOption';
import getDateRangeOptionNormalized from './helpers/getDateRangeOptionNormalized';
import getUpdatedTemplateMapping from './helpers/getUpdatedTemplateMapping';
import EvertransitField from './components/evertransitField';
import DateRangeSelector from './components/dateRangeSelector';
import TemplateOptions from './components/templateOptions';
import FieldOptions from './components/fieldOptions';
import {
    Container,
    TemplateSelector,
    TableContainer,
    ExportTable,
    TrashIcon,
} from './styled';

function useFieldNameComponent(allowEditTemplate, onChange) {
    if (allowEditTemplate) {
        return (props) => <InlineEditField {...props} onChange={onChange} />;
    }
    return undefined;
}

function FieldMapping() {
    const [isOpen, setIsOpen] = useState(false);
    const [dateRangeOption, setDateRangeOption] = usePersistentState('dateRangeOption', {});
    const [template, setTemplate] = usePersistentState('exportTemplate', {});
    const [dateRangeError, setDateRangeError] = useState();
    const [showErrors, setShowErrors] = useState(false);
    const { groupId } = useParams();
    const history = useHistory();
    const templates = useExportTemplates();
    const exportFields = useExportFields();
    const mapping = template.value && template.value.mapping;
    const mappingData = getMappingTableData({
        exportFields,
        mapping,
        showErrors,
    });
    const extraExportFields = getExtraExportFields({
        exportFields,
        mapping,
    });
    const isExtraFieldsButtonDisabled = extraExportFields.length === 0;
    const allowEditTemplate = template.value && template.value.allowEdit;
    const normalizedDateRangeOption = getDateRangeOptionNormalized(dateRangeOption);

    useEffect(() => {
        if (isFilledArray(templates)) {
            const evertransitTemplate = find((item) => item.name === 'Evertransit Template', templates);
            if (!template.value) {
                const { id, name } = evertransitTemplate;
                setTemplate({
                    name: id,
                    label: name,
                    value: evertransitTemplate,
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templates]);

    const handleRangeChange = (value) => {
        if (value.name === 'custom') {
            return setIsOpen(true);
        }
        setDateRangeError();
        return setDateRangeOption(value);
    };

    const handleDatePickerChange = (dates) => {
        if (Array.isArray(dates) && dates.length === 2) {
            setIsOpen(false);
        }
        return setDateRangeOption({
            name: 'custom',
            label: getFormattedRangeDate(dates),
            value: dates,
        });
    };

    const handleFieldNameChange = (value, row) => {
        setTemplate(getUpdatedTemplateMapping({
            template,
            key: row.evertransitField,
            value,
        }));
    };

    const addRow = (field) => {
        const { evertransitField, defaultName } = field;
        setTemplate(getUpdatedTemplateMapping({
            template,
            key: evertransitField,
            value: defaultName,
        }));
    };

    const removeRow = (row) => {
        setTemplate(getUpdatedTemplateMapping({
            template,
            key: row.evertransitField,
            value: undefined,
        }));
    };

    const onExportData = async () => {
        const isInvalidDateRange = !isValidDateRangeOption(normalizedDateRangeOption);
        const isInvalidMapping = mappingData.some((item) => !item.fieldName);
        if (isInvalidDateRange) {
            setDateRangeError('Select the date range of the rides you want to export.');
        }
        if (isInvalidMapping) {
            setShowErrors(true);
        }
        if (mappingData.length === 0) {
            return showAppMessage({
                message: 'You need to add fields in order to export rides',
                variant: 'error',
            });
        }
        if (!isInvalidDateRange && !isInvalidMapping) {
            const ridesSample = await fetchRidesSample({
                groupId,
                dateRange: normalizedDateRangeOption.value,
                mapping: mappingData,
                statuses: [],
            });
            if (ridesSample.rides.length > 0 && ridesSample.total > 0) {
                history.push(`/app/${groupId}/export/preview`, {
                    mapping: mappingData,
                    ridesSample,
                    dateRangeOption: normalizedDateRangeOption,
                });
            } else {
                showAppMessage({
                    message: 'Sorry, we couldn\'t find any ride for this time range',
                    variant: 'error',
                });
            }
        }
        return null;
    };

    return (
        <Container>
            <Header
                className="rainbow-m-horizontal_small"
                leftContent={(
                    <DateRangeSelector
                        onChange={handleRangeChange}
                        value={normalizedDateRangeOption}
                        error={dateRangeError}
                    />
                )}
                rightContent={(
                    <>
                        <TemplateSelector
                            label="Select Template"
                            hideLabel
                            placeholder="Select Template"
                            className="rainbow-m-right_large"
                            variant="shaded"
                            onChange={setTemplate}
                            value={template}
                            id="export-template-selector"
                        >
                            <Option name="header" label="Templates" variant="header" />
                            <TemplateOptions templates={templates} />
                        </TemplateSelector>

                        <Button
                            label="Export Data"
                            variant="brand"
                            shaded
                            onClick={onExportData}
                            id="export-data-button"
                        />
                    </>
                )}
            />
            <TableContainer>
                <ExportTable
                    keyField="evertransitField"
                    data={mappingData}
                    showRowNumberColumn
                    id="export-mapping-table"
                >
                    <Column
                        header="Evertransit Fields"
                        defaultWidth={320}
                        field="evertransitField"
                        component={EvertransitField}
                    />
                    <Column
                        header="Field Names"
                        defaultWidth={220}
                        field="fieldName"
                        component={useFieldNameComponent(allowEditTemplate, handleFieldNameChange)}
                    />
                    <Column header="Description" field="decription" />
                    {allowEditTemplate ? (
                        <Column
                            component={(props) => (
                                <ButtonIcon
                                    icon={<TrashIcon />}
                                    // eslint-disable-next-line react/prop-types
                                    onClick={() => removeRow(props.row)}
                                    id="trash-button"
                                />
                            )}
                            defaultWidth={36}
                        />
                    ) : null}
                </ExportTable>
                <RenderIf isTrue={allowEditTemplate}>
                    <ButtonMenu
                        className="rainbow-m-around_medium"
                        buttonVariant="neutral"
                        shaded
                        menuAlignment="bottom-right"
                        menuSize="x-small"
                        icon={<Plus />}
                        disabled={isExtraFieldsButtonDisabled}
                        id="add-row-button"
                    >
                        <MenuItem label="Evertransit Fields" variant="header" />
                        <FieldOptions options={extraExportFields} onAddRow={addRow} />
                    </ButtonMenu>
                </RenderIf>
            </TableContainer>
            <DatePickerModal
                title={getDatePickerTitle(
                    normalizedDateRangeOption.label,
                    normalizedDateRangeOption.value,
                )}
                onRequestClose={() => setIsOpen(false)}
                isOpen={isOpen}
                size="medium"
                selectionType="range"
                variant="double"
                value={getDateValue(normalizedDateRangeOption.value)}
                onChange={handleDatePickerChange}
            />
        </Container>
    );
}

export default FieldMapping;
