import React from 'react';
import PropTypes from 'prop-types';

export default function PhoneCircleFilled(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="24px" height="24px" viewBox="0 0 24 24">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-import-2" transform="translate(-484.000000, -213.000000)">
                    <g id="phone" transform="translate(484.000000, 213.000000)">
                        <g id="Group-56" fill="#F4F6F9">
                            <circle id="Oval" cx="12" cy="12" r="12" />
                        </g>
                        <path d="M17.4372169,14.1783301 C16.6475336,14.1783301 15.8721593,14.0548225 15.1373944,13.8120058 C14.7773512,13.6892035 14.3347457,13.8018618 14.1150048,14.027548 L12.6647217,15.122356 C10.9828071,14.2245489 9.94678023,13.188858 9.06123321,11.5195729 L10.1238292,10.1070777 C10.3998992,9.83137716 10.4989203,9.42864203 10.3802831,9.05076296 C10.1364251,8.31213532 10.012548,7.53713052 10.012548,6.74711132 C10.0125816,6.17639635 9.54827735,5.71209213 8.97759597,5.71209213 L6.60888196,5.71209213 C6.03820058,5.71209213 5.57389635,6.17639635 5.57389635,6.74707774 C5.57389635,13.2885845 10.8957102,18.6103647 17.4372169,18.6103647 C18.0078983,18.6103647 18.4722025,18.1460605 18.4722025,17.5753791 L18.4722025,15.2132821 C18.4722025,14.6426344 18.0078647,14.1783301 17.4372169,14.1783301 Z" id="Path" fill="#9191ab" fillRule="nonzero" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

PhoneCircleFilled.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

PhoneCircleFilled.defaultProps = {
    style: {},
    className: undefined,
};
