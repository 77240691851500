import React from 'react';
import {
    Route,
    Switch,
    Redirect,
    useParams,
} from 'react-router-dom';
import { Container } from './styled';
import FilePicker from './FilePicker';
import FieldMapping from './FieldMapping';
import Preview from './Preview';
import Done from './Done';
import ServiceLevelMapping from './ServiceLevelMapping';

function Import() {
    const params = useParams();

    return (
        <Container>
            <Switch>
                <Route exact path="/app/:groupId/import" component={FilePicker} />
                <Route exact path="/app/:groupId/import/fieldMapping" component={FieldMapping} />
                <Route exact path="/app/:groupId/import/preview" component={Preview} />
                <Route exact path="/app/:groupId/import/serviceMapping" component={ServiceLevelMapping} />
                <Route exact path="/app/:groupId/import/done" component={Done} />
                <Redirect to={`/app/${params.groupId}/import`} />
            </Switch>
        </Container>
    );
}

export default Import;
