import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { PicklistOption } from 'react-rainbow-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import getUserFleets from '../../services/firebase/getUserFleets';
import { Container } from './styled';

function FleetOptions({ fleets }) {
    return fleets.data.map(({ id, name }) => <PicklistOption key={id} name={id} label={name} />);
}

export default function FleetSelector(props) {
    const {
        className,
        style,
    } = props;
    const [fleets, setFleets] = useState({ data: [] });
    const [isLoading, setIsLoading] = useState(true);
    const match = useRouteMatch('/app/:groupId');
    const history = useHistory();
    const pathAfterGroupId = history.location.pathname.split('/').slice(3).join('/');

    const group = useMemo(() => {
        if (match && match.params && match.params.groupId) {
            const groupSelected = fleets.data.find((fleet) => fleet.id === match.params.groupId);
            if (groupSelected) {
                const { id, name } = groupSelected;
                return {
                    name: id,
                    label: name,
                };
            }
        }
        return undefined;
    }, [fleets, match]);

    useEffect(() => {
        (async () => {
            try {
                const groups = await getUserFleets();
                setIsLoading(false);
                setFleets(groups);
            } catch (error) {
                setIsLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (fleets.data && fleets.data.length > 0 && !group) {
            history.push(`/app/${fleets.data[0].id}/${pathAfterGroupId}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fleets, group]);

    return (
        <Container
            className={className}
            style={style}
            label="Select Fleet"
            hideLabel
            placeholder="Select Fleet"
            enableSearch={!isLoading}
            value={group}
            isLoading={isLoading}
            id="fleet-selector"
            onChange={(value) => history.push(`/app/${value.name}/${pathAfterGroupId}`)}
        >
            <PicklistOption name="header" label="Select Fleet" variant="header" />
            <FleetOptions fleets={fleets} />
        </Container>
    );
}

FleetSelector.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

FleetSelector.defaultProps = {
    className: undefined,
    style: undefined,
};
