import isFromFieldsDefined from './isFromFieldsDefined';

function getFieldsInfo(mapping) {
    if (mapping) {
        return Object.keys(mapping).sort((a, b) => {
            const fieldA = mapping[a];
            const fieldB = mapping[b];
            return fieldA.order - fieldB.order;
        }).reduce((acc, fieldName) => {
            const fieldValue = mapping[fieldName];
            const {
                type, typeSelected, label, fromFields,
            } = fieldValue;
            const hasFromFields = isFromFieldsDefined(fromFields);
            if (type instanceof Array) {
                if (typeSelected === 'full-address') {
                    const field = `${fieldName}.value`;
                    const header = label || fieldName;
                    const info = {
                        header,
                        field,
                        hasFromFields,
                    };
                    return [...acc, info];
                }
                if (typeSelected === 'address-elements') {
                    const typeSelectedValue = type.find((typeDefinition) => typeDefinition.type === 'address-elements');
                    const { subFields } = typeSelectedValue;
                    const subFieldsInfo = getFieldsInfo(subFields);
                    const fields = subFieldsInfo.map((subFieldInfo) => {
                        const { field } = subFieldInfo;
                        return {
                            ...subFieldInfo,
                            field: `${fieldName}.${field}`,
                        };
                    });
                    return [...acc, ...fields];
                }
                if (typeSelected === 'single-service' || typeSelected === 'multi-service') {
                    const field = `${fieldName}.label`;
                    const header = label || fieldName;
                    const info = {
                        header,
                        field,
                        hasFromFields,
                    };
                    return [...acc, info];
                }
            }
            const info = {
                header: label || fieldName,
                field: fieldName,
                hasFromFields,
            };
            return [...acc, info];
        }, []);
    }
    return [];
}

export default function getPreviewTableColumnInfo(mapping) {
    const fieldsInfo = getFieldsInfo(mapping);
    return fieldsInfo.filter((fieldInfo) => fieldInfo.hasFromFields).map((fieldInfo) => {
        const { field, header } = fieldInfo;
        return {
            field,
            header,
        };
    });
}
