import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowBack(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="16px" height="14px" viewBox="0 0 16 14">
            <g id="ArrowBack-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-import-2" transform="translate(-124.000000, -106.000000)" fill="currentColor" fillRule="nonzero">
                    <g id="Group-19" transform="translate(108.000000, 93.000000)">
                        <path d="M31.3333333,19 L18.0336667,19 L23.1263333,14.1493333 C23.393,13.8953333 23.4033333,13.4733333 23.1493333,13.2066667 C22.8956667,12.9403333 22.4736667,12.9296667 22.2066667,13.1836667 L16.3906667,18.7236667 C16.139,18.9756667 16,19.3103333 16,19.6666667 C16,20.0226667 16.139,20.3576667 16.4023333,20.6206667 L22.207,26.1493333 C22.336,26.2723333 22.5013333,26.3333333 22.6666667,26.3333333 C22.8426667,26.3333333 23.0186667,26.264 23.1496667,26.1263333 C23.4036667,25.8596667 23.3933333,25.438 23.1266667,25.184 L18.0126667,20.3333333 L31.3333333,20.3333333 C31.7013333,20.3333333 32,20.0346667 32,19.6666667 C32,19.2986667 31.7013333,19 31.3333333,19 L31.3333333,19 Z" id="back" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

ArrowBack.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

ArrowBack.defaultProps = {
    style: {},
    className: undefined,
};
