import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InternalOverlay from 'react-rainbow-components/components/InternalOverlay';
import InternalDropdown from 'react-rainbow-components/components/InternalDropdown';
import Option from 'react-rainbow-components/components/Option';
import { useOutsideClick } from '@rainbow-modules/hooks';
import { ArrowButtonIcon, OptionsContainer } from './styled';
import ChevronDown from '../icons/ChevronDown';
import { labelMap } from './constants';

function Options({ items }) {
    return items.map(({ type }) => <Option key={type} name={type} label={labelMap[type]} />);
}

const MultiTypePicker = ({ options, value, onChange }) => {
    const triggerRef = useRef();
    const dropdownContainerRef = useRef();
    const dropdownRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    const handleOutsideClick = (event) => {
        if (!triggerRef.current.buttonRef.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useOutsideClick(
        dropdownContainerRef,
        handleOutsideClick,
        isVisible,
    );

    const handleClick = () => {
        setIsVisible(!isVisible);
    };

    const handleChange = (option) => {
        setIsVisible(false);
        onChange(option);
    };

    return (
        <>
            <ArrowButtonIcon onClick={handleClick} icon={<ChevronDown />} ref={triggerRef} id="type-selector" />
            <InternalOverlay
                isVisible={isVisible}
                onOpened={() => dropdownRef.current.focus()}
                render={() => (
                    <OptionsContainer ref={dropdownContainerRef}>
                        <InternalDropdown onChange={handleChange} value={value} ref={dropdownRef}>
                            <Options items={options} />
                        </InternalDropdown>
                    </OptionsContainer>
                )}
                triggerElementRef={() => triggerRef.current.buttonRef}
            />
        </>
    );
};

MultiTypePicker.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    options: PropTypes.array,
};

MultiTypePicker.defaultProps = {
    value: undefined,
    onChange: () => {},
    options: [],
};

export default MultiTypePicker;
