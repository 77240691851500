const theme = {
    rainbow: {
        palette: {
            brand: '#1aa287',
            success: '#79c9a6',
        },
    },
};

export default theme;

export const chipsTheme = {
    rainbow: {
        palette: {
            brand: '#d8f2ee',
        },
    },
};
