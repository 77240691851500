import React from 'react';
import PropTypes from 'prop-types';

export default function Checkmark(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="32px" height="32px" viewBox="0 0 32 32">
            <g id="data-app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-54">
                    <rect id="Rectangle" fillOpacity="0" fill="#D8D8D8" x="0" y="0" width="32" height="32" />
                    <path d="M31.2015364,9.14941383 L13.4999551,26.8504964 C12.9688928,27.3815589 12.2717799,27.6488354 11.574667,27.6488354 C10.877554,27.6488354 10.1804412,27.3815589 9.64937881,26.8504964 L0.798837523,17.9999551 C-0.266279174,16.9353372 -0.266279174,15.2139968 0.798837523,14.1493788 C1.86345547,13.0842621 3.58429714,13.0842621 4.64941383,14.1493788 L11.574667,21.0746319 L27.3509601,5.29883752 C28.4155781,4.23372083 30.1364197,4.23372083 31.2015364,5.29883752 C32.2661545,6.36345547 32.2661545,8.08429714 31.2015364,9.14941383 Z" id="Path" fill="currentColor" fillRule="nonzero" />
                </g>
            </g>
        </svg>
    );
}

Checkmark.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Checkmark.defaultProps = {
    style: {},
    className: undefined,
};
