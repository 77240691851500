import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { showAppMessage, showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import {
    Button, Table, Column, Input,
} from 'react-rainbow-components';
import shortid from 'shortid';
import exportData from '../../../services/firebase/exportData';
import ArrowBack from '../../../components/icons/ArrowBack';
import getNormalizedRides from './helpers/getNormalizedRides';
import getNormalizedMapping from './helpers/getNormalizedMapping';
import {
    Container,
    Title,
    BackButton,
    Description,
    LeftContent,
    HeaderContainer,
} from './styled';

function Preview({ location }) {
    const { state } = location;
    const { groupId } = useParams();
    const history = useHistory();

    if (state && state.ridesSample && state.mapping) {
        const { ridesSample: { rides, total }, mapping, dateRangeOption } = state;
        const keyField = shortid.generate();
        const normalizedRides = getNormalizedRides({ keyField, rides });
        const normalizedMapping = getNormalizedMapping(mapping);

        const exportRides = async () => {
            const startDate = dateRangeOption.value[0].getTime();
            const endDate = dateRangeOption.value[1].getTime();
            try {
                showAppSpinner();
                const response = await exportData({
                    groupId,
                    startDate,
                    endDate,
                    statuses: [],
                    mapping,
                });
                hideAppSpinner();
                history.replace(`/app/${groupId}/export/done`, {
                    total,
                    exportId: response.data.id,
                });
                sessionStorage.clear();
            } catch (error) {
                hideAppSpinner();
                showAppMessage({
                    variant: 'error',
                    message: error.message || 'Something was wrong, try again later',
                });
            }
        };

        return (
            <Container>
                <HeaderContainer
                    className="rainbow-m-bottom_small"
                    leftContent={(
                        <LeftContent>
                            <BackButton data-cy="export-preview-back-button" to="app/import">
                                <ArrowBack />
                            </BackButton>
                            <div>
                                <Title>Preview</Title>
                                <Description>{`Total: ${total} rides`}</Description>
                            </div>
                        </LeftContent>
                    )}
                    rightContent={(
                        <>
                            <Input className="rainbow-m-right_large" placeholder={dateRangeOption.label} readOnly isCentered />
                            <Button
                                label="Export Now"
                                variant="brand"
                                shaded
                                onClick={exportRides}
                            />
                        </>
                    )}
                />
                <Table
                    className="rainbow-m-bottom_large rainbow-m-top_small"
                    showRowNumberColumn
                    minColumnWidth="200"
                    keyField={keyField}
                    data={normalizedRides}
                >
                    {normalizedMapping.map(({ fieldName, normalizedField }) => (
                        <Column
                            key={fieldName}
                            header={fieldName}
                            field={normalizedField}
                        />
                    ))}
                </Table>
            </Container>
        );
    }
    return <Redirect to={`/app/${groupId}/export`} />;
}

Preview.propTypes = {
    location: PropTypes.object.isRequired,
};

export default Preview;
