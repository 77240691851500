import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { SelectFile } from './styled';
import messages from './messages';
import processCsvFile from './processCsvFile';
import serializeDataToSessionStorage from '../../../services/helpers/serializeDataToSessionStorage';

function FilePicker() {
    const [error, setError] = useState();
    const intl = useIntl();
    const history = useHistory();
    const { groupId } = useParams();
    const onChange = async (files) => {
        if (files.length > 0) {
            const file = files[0];
            if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel') {
                setError(undefined);
                const [data, headers] = await processCsvFile(file);
                const isEmpty = data.length === 0 || headers.length === 0;
                if (isEmpty) {
                    return setError(intl.formatMessage(messages.emptyFile));
                }
                sessionStorage.clear();
                serializeDataToSessionStorage('fileData', [data, headers]);
                return history.push(`/app/${groupId}/import/fieldMapping`);
            }
            return setError(intl.formatMessage(messages.invalidFile));
        }
        return setError(undefined);
    };
    return (
        <SelectFile
            variant="multiline"
            error={error}
            onChange={onChange}
            accept="text/csv"
            id="file-selector"
        />
    );
}

export default FilePicker;
