import isFilledArray from '../../../../services/helpers/isFilledArray';

export default function getUpdatedMapping(params) {
    const {
        mapping, toField, fromFields, typeSelected = null,
    } = params;
    const fieldNames = toField.split('.');
    const isNested = isFilledArray(fieldNames)
        && fieldNames.length > 1
        && isFilledArray(mapping[fieldNames[0]].type);

    if (isNested) {
        return {
            ...mapping,
            [fieldNames[0]]: {
                ...mapping[fieldNames[0]],
                type: [
                    mapping[fieldNames[0]].type[0],
                    {
                        ...mapping[fieldNames[0]].type[1],
                        subFields: {
                            ...mapping[fieldNames[0]].type[1].subFields,
                            [fieldNames[1]]: {
                                ...mapping[fieldNames[0]].type[1].subFields[fieldNames[1]],
                                fromFields,
                            },
                        },
                    },
                ],
            },
        };
    }

    const field = mapping[toField];

    if (Array.isArray(field.type)) {
        return {
            ...mapping,
            [toField]: {
                ...field,
                fromFields,
                typeSelected: typeSelected || field.typeSelected,
            },
        };
    }
    return {
        ...mapping,
        [toField]: {
            ...field,
            fromFields,
        },
    };
}
