import styled from 'styled-components';

export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 32px;
`;

export const LeftContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const RightContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
