import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import useImportStats from '../../../hooks/useImportStats';
import OutputColumn from '../../../components/OutputColumn';
import {
    Container,
    Card,
    StartButton,
    Content,
    Footer,
    Divider,
} from './styled';

function getGroupId(match) {
    if (match && match.params && match.params.groupId) {
        return match.params.groupId;
    }
    return '';
}

export default function Done() {
    const match = useRouteMatch('/app/:groupId');
    const groupId = getGroupId(match);
    const [importStats = {}] = useImportStats();
    const { validCount = 0, invalidCount = 0, total = 0 } = importStats;

    return (
        <Container>
            <Content>
                <Card
                    title="Import Completed"
                    description="The file has been successfully uploaded and your rides are now processing."
                    footer={(
                        <Footer>
                            <OutputColumn
                                label="Total"
                                value={total}
                                id="total"
                            />
                            <Divider />
                            <OutputColumn
                                label="Imported"
                                value={validCount}
                                variant="brand"
                                id="imported"
                            />
                            <Divider />
                            <OutputColumn
                                label="Errors"
                                value={invalidCount}
                                variant="error"
                                id="error"
                            />
                        </Footer>
                    )}

                />
                <StartButton data-cy="start-import-link" className="rainbow-m-vertical_large" to={`/app/${groupId}/import`}>
                    Start a new Import?
                </StartButton>
            </Content>

        </Container>
    );
}
