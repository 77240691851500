import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Notification as RainbowNotification, Spinner } from 'react-rainbow-components';
import Description from './description';

const titleMap = {
    completed: 'Process Completed',
    pending: 'Pending',
    inProgress: 'Creating File',
    failed: 'Failed to process file',
};

const Loading = <Spinner isVisible type="arc" variant="brand" style={{ position: 'relative' }} />;

const iconMap = {
    completed: 'success',
    pending: Loading,
    inProgress: Loading,
    failed: 'error',
};

export default function Notification({ data, exportId }) {
    const [isOpen, setIsOpen] = useState(true);

    if (data && isOpen) {
        const {
            endDate,
            startDate,
            status,
        } = data;
        const title = titleMap[status];
        const icon = iconMap[status];

        return (
            // TODO: move to styled
            <RainbowNotification
                style={{
                    position: 'absolute',
                    right: 16,
                    top: 0,
                }}
                title={title}
                description={(
                    <Description
                        status={status}
                        startDate={startDate}
                        endDate={endDate}
                        exportId={exportId}
                    />
                )}
                icon={icon}
                onRequestClose={() => setIsOpen(false)}
            />
        );
    }
    return null;
}

Notification.propTypes = {
    data: PropTypes.object,
    exportId: PropTypes.string,
};

Notification.defaultProps = {
    data: null,
    exportId: '',
};
