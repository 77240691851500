import { useState, useEffect } from 'react';
import { showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import getExportTemplates from '../../services/firebase/getExportTemplates';

export default function useExportTemplates() {
    const [templates, setTemplates] = useState([]);
    useEffect(() => {
        (async () => {
            try {
                showAppSpinner();
                const templatesRes = await getExportTemplates();
                setTemplates(templatesRes);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
            hideAppSpinner();
        })();
    }, []);
    return templates;
}
