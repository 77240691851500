import React from 'react';
import { Column } from 'react-rainbow-components';
import getCurrentUserId from '../../services/firebase/getCurrentUserId';
import StatusBadge from '../../components/StatusBadge';
import DateTime from '../../components/DateTime';
import DownloadButton from './DownloadButton';
import {
    Container,
    HistoryTable,
} from './styled';

export default function History() {
    return (
        <Container>
            <HistoryTable
                showRowNumberColumn
                collection={`users/${getCurrentUserId()}/exports`}
                fetchOnce
                defaultSortDirection="desc"
                sortedBy="createdAt"
                id="mapping-table"
            >
                <Column
                    header="File Name"
                    field="fileName"
                />
                <Column
                    header="Status"
                    field="status"
                    component={StatusBadge}
                />
                <Column
                    header="Created at"
                    field="createdAt"
                    sortable
                    component={DateTime}
                />
                <Column
                    defaultWidth={150}
                    field="allow_download"
                    component={DownloadButton}
                />
            </HistoryTable>
        </Container>
    );
}
