import isValidObjectAccordingToMapping from './isValidObjectAccordingToMapping';
import getNormalizedObjectAccordingToMapping from './getNormalizedObjectAccordingToMapping';

export default function getValidatedData(data = [], mapping) {
    return data.reduce(({ validData, invalidData }, currentObject) => {
        const normalizedObject = getNormalizedObjectAccordingToMapping(currentObject, mapping);
        if (isValidObjectAccordingToMapping(normalizedObject, mapping)) {
            return {
                validData: [...validData, normalizedObject],
                invalidData,
            };
        }
        return {
            validData,
            invalidData: [...invalidData, currentObject],
        };
    }, {
        validData: [],
        invalidData: [],
    });
}
