import React from 'react';
import PropTypes from 'prop-types';

export default function Download(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="16px" height="16px" viewBox="0 0 16 16">
            <g id="data-app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-history" transform="translate(-1400.000000, -201.000000)" fill="currentColor" fillRule="nonzero">
                    <g id="Group-11" transform="translate(86.000000, 145.000000)">
                        <path d="M1329.34084,65.8588764 C1329.67975,65.8588764 1329.95448,66.133609 1329.95448,66.4725213 L1329.95448,66.4725213 L1329.95448,70.1543191 C1329.95448,71.1710202 1329.13028,71.995218 1328.11358,71.995218 L1328.11358,71.995218 L1315.8409,71.995218 C1314.8242,71.995218 1314,71.1710202 1314,70.1543191 L1314,70.1543191 L1314,66.4725213 C1314,66.133609 1314.27473,65.8588764 1314.61364,65.8588764 C1314.95256,65.8588764 1315.22729,66.133609 1315.22729,66.4725213 L1315.22729,66.4725213 L1315.22729,70.1543191 C1315.22729,70.4932315 1315.50202,70.767964 1315.84093,70.767964 L1315.84093,70.767964 L1328.11355,70.767964 C1328.45246,70.767964 1328.72719,70.4932315 1328.72719,70.1543191 L1328.72719,70.1543191 L1328.72719,66.4725213 C1328.72719,66.133609 1329.00192,65.8588764 1329.34084,65.8588764 Z M1321.97724,56 C1322.31615,56 1322.59089,56.2747326 1322.59089,56.6136449 L1322.59089,56.6136449 L1322.59089,67.4457169 L1324.61282,65.4250427 C1324.85059,65.1953978 1325.22758,65.1953978 1325.46538,65.4250427 C1325.70916,65.6604764 1325.71592,66.0489348 1325.48045,66.2927101 L1325.48045,66.2927101 L1322.41173,69.3614337 L1322.41173,69.3614337 L1322.32611,69.4328681 C1322.08676,69.5982267 1321.75613,69.5741323 1321.54337,69.3608629 L1321.54337,69.3608629 L1318.47522,66.2927101 C1318.24554,66.0549034 1318.24554,65.6779146 1318.47522,65.4401079 C1318.71065,65.1963685 1319.09911,65.189609 1319.34289,65.4250427 L1319.34289,65.4250427 L1321.3636,67.4457528 L1321.3636,56.6136449 C1321.3636,56.2747326 1321.63833,56 1321.97724,56 Z" id="download" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Download.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Download.defaultProps = {
    style: {},
    className: undefined,
};
