import isValidEmail from './isValidEmail';
import isValidDate from './isValidDate';
import isValidText from './isValidText';
import isValidFullAddress from './isValidFullAddress';
import isValidNumber from './isValidNumber';
import isValidServiceLevel from './isValidServiceLevel';

export function isRequiredField(fieldName, objectToValidate, mapping) {
    const currentFieldValidationMapping = mapping[fieldName] || {};
    const { isRequired, requiredIfEmpty = [] } = currentFieldValidationMapping;

    if (isRequired) return true;

    const isRequiredIfEmptyFieldType = requiredIfEmpty instanceof Array
        && requiredIfEmpty.length > 0;

    if (isRequiredIfEmptyFieldType) {
        const hasSomeValue = requiredIfEmpty.some((requiredFieldName) => {
            const fieldValueToValidate = objectToValidate[requiredFieldName];
            const fieldMapping = mapping[requiredFieldName];
            // eslint-disable-next-line no-use-before-define
            return isDefinedAndValidValue(fieldValueToValidate, fieldMapping);
        });
        return !hasSomeValue;
    }
    return false;
}

export function isDefinedAndValidValue(fieldValueToValidate, fieldMapping = {}) {
    if (fieldValueToValidate !== undefined) {
        const { type, typeSelected } = fieldMapping;
        const isComposedType = type instanceof Array;
        if (isComposedType && typeSelected === 'address-elements') {
            const { type: typeValue, ...addressElements } = fieldValueToValidate;
            if (typeValue === 'address-elements') {
                const selectedTypeValue = type.find((fieldType) => fieldType.type === 'address-elements');
                const { subFields: subFieldsMapping } = selectedTypeValue;
                // eslint-disable-next-line no-use-before-define
                return isValidObjectAccordingToMapping(addressElements, subFieldsMapping);
            }
            return false;
        }
        if (isComposedType && typeSelected === 'full-address') {
            return isValidFullAddress(fieldValueToValidate);
        }
        if (isComposedType && typeSelected === 'single-service') {
            return isValidServiceLevel(fieldValueToValidate);
        }
        if (isComposedType && typeSelected === 'multi-service') {
            return isValidServiceLevel(fieldValueToValidate);
        }
        if (type === 'text' || type === 'address' || type === 'phone-number') {
            return isValidText(fieldValueToValidate);
        }
        if (type === 'email') {
            return isValidEmail(fieldValueToValidate);
        }
        if (type === 'date') {
            return isValidDate(fieldValueToValidate);
        }
        if (type === 'number' || type === 'currency') {
            return isValidNumber(fieldValueToValidate);
        }
    }
    return false;
}

const hasValidField = (fieldName, objectToValidate, mapping) => {
    if (isRequiredField(fieldName, objectToValidate, mapping)) {
        const fieldValueToValidate = objectToValidate[fieldName];
        const fieldMapping = mapping[fieldName];
        return isDefinedAndValidValue(fieldValueToValidate, fieldMapping);
    }
    return true;
};

export default function isValidObjectAccordingToMapping(objectToValidate, mapping = {}) {
    const fieldNames = Object.keys(mapping);
    return fieldNames.every((fieldName) => hasValidField(fieldName, objectToValidate, mapping));
}
