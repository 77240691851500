import styled from 'styled-components';
import Pencil from '../../icons/Pencil';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
`;

export const Placeholder = styled.h1`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Value = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const PencilIcon = styled(Pencil)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const Cell = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Input = styled.input`
    width: 100%;
    padding: 4px;
    line-height: 2;
    margin-top: 1px;
    border: 1px dotted ${(props) => props.theme.rainbow.palette.brand.main};
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};

    ::placeholder {
        font-size: 14px;
        color: ${(props) => props.theme.rainbow.palette.text.header};
    }

    &:focus,
    &:active,
    &:visited {
        outline: 0;
    }
`;
