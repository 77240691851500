import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, Label, Value,
} from './styled';

export default function OutputColumn(props) {
    const {
        className,
        style,
        label,
        value,
        variant,
        id,
    } = props;

    return (
        <Container
            className={className}
            style={style}
            id={id}
        >
            <Label>{label}</Label>
            <Value variant={variant}>{value}</Value>
        </Container>
    );
}


OutputColumn.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    variant: PropTypes.oneOf([
        'default',
        'error',
        'success',
        'brand',
    ]),
};

OutputColumn.defaultProps = {
    className: undefined,
    id: undefined,
    style: undefined,
    label: undefined,
    value: undefined,
    variant: 'default',
};
