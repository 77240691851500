import React, { useCallback } from 'react';

const useAsColumn = (Component) => useCallback(({ value, row }) => {
    if (typeof value === 'object') {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Component {...value} row={row} />;
    }
    return <Component value={value} row={row} />;
}, []);

export default useAsColumn;
