import { defineMessages } from 'react-intl';

const messages = defineMessages({
    invalidFile: {
        id: 'app.import.invalidFile',
        defaultMessage: 'Sorry, We only suppport CSV files.',
    },
    emptyFile: {
        id: 'app.import.emptyFile',
        defaultMessage: 'Sorry, We couldn\'t find any records on that file.',
    },
});

export default messages;
