import styled from 'styled-components';
import { SideBarNavigation } from '@rainbow-modules/app';
import Logo from '../../../components/icons/ImportExportLogo';
import Import from '../../../components/icons/Import';

export const Container = styled.section`
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    width: 88px;
    border-radius: 20px;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_2};
    padding: 16px 0;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
`;

export const Bar = styled(SideBarNavigation)`
    width: 100%;
`;

export const AppLogo = styled(Logo)`
    width: 52px;
    height: 52px;
`;

export const SidebarIcon = styled(Import)`
    width: 36px;
    height: 36px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;
