import styled from 'styled-components';

export const StyledAppContainer = styled.section`
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: #f4f6f9;
    box-sizing: border-box;
    overflow: auto;
    padding: 8px 0 8px 8px;
`;

export const StyledContent = styled.main`
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;
