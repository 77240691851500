import firebaseInstance from '../../firebase';

export default async function getCollection(path) {
    const ref = firebaseInstance.firestore().collection(path);
    const collection = await ref.get();
    return collection.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
    }));
}
