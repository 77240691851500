import React from 'react';
import PropTypes from 'prop-types';

export default function CheckmarkCircle(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="102px" height="102px" viewBox="0 0 102 102">
            <g fill="none">
                <path fill="currentColor" d="M50 0C22.429 0 0 22.429 0 50s22.429 50 50 50 50-22.429 50-50S77.571 0 50 0z" transform="translate(1 1)" />
                <path fill="#FFF" d="M71.132 40.427l-24.47 24.47C45.928 65.63 44.964 66 44 66c-.963 0-1.927-.37-2.661-1.104L29.104 52.662c-1.472-1.472-1.472-3.851 0-5.323 1.472-1.472 3.85-1.472 5.323 0L44 56.912 65.81 35.104c1.472-1.472 3.85-1.472 5.323 0 1.471 1.472 1.471 3.85 0 5.323z" transform="translate(1 1)" />
            </g>
        </svg>
    );
}

CheckmarkCircle.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

CheckmarkCircle.defaultProps = {
    style: {},
    className: undefined,
};
