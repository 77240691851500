import getNormalizedFullAddress from './getNormalizedFullAddress';
import isValidEmail from './isValidEmail';
import isValidDate from './isValidDate';
import isValidNumber from './isValidNumber';
import isValidPhoneNumber from './isValidPhoneNumber';

export function getNormalizedField(fieldValueToNormalize, fieldMapping = {}) {
    if (fieldValueToNormalize !== undefined) {
        const { type, typeSelected } = fieldMapping;
        const isComposedType = type instanceof Array;
        if (isComposedType && typeSelected === 'address-elements') {
            const { type: typeValue, ...addressElements } = fieldValueToNormalize;
            const selectedTypeValue = type.find((fieldType) => fieldType.type === 'address-elements');
            const { subFields: subFieldsMapping } = selectedTypeValue;
            return {
                type: 'address-elements',
                // eslint-disable-next-line no-use-before-define
                ...getNormalizedObjectAccordingToMapping(addressElements, subFieldsMapping),
            };
        }
        if (isComposedType && typeSelected === 'full-address') {
            return getNormalizedFullAddress(fieldValueToNormalize);
        }
        if (isComposedType && typeSelected === 'single-service' && fieldValueToNormalize.label && fieldValueToNormalize.name) {
            return fieldValueToNormalize;
        }
        if (isComposedType && typeSelected === 'multi-service' && fieldValueToNormalize.label && fieldValueToNormalize.name) {
            return fieldValueToNormalize;
        }
        if (type === 'text' || type === 'address') {
            return String(fieldValueToNormalize);
        }
        if (type === 'phone-number') {
            return isValidPhoneNumber(fieldValueToNormalize)
                ? String(fieldValueToNormalize)
                : undefined;
        }
        if (type === 'email') {
            return isValidEmail(fieldValueToNormalize) ? fieldValueToNormalize : undefined;
        }
        if (type === 'date') {
            return isValidDate(fieldValueToNormalize) ? String(fieldValueToNormalize) : undefined;
        }
        if (type === 'number' || type === 'currency') {
            return isValidNumber(fieldValueToNormalize) ? Number(fieldValueToNormalize) : undefined;
        }
    }
    return undefined;
}

export default function getNormalizedObjectAccordingToMapping(objectToNormalize, mapping = {}) {
    const fieldNames = Object.keys(mapping);
    return fieldNames.reduce((acc, fieldName) => {
        const normalizedFieldValue = getNormalizedField(
            objectToNormalize[fieldName],
            mapping[fieldName],
        );
        if (normalizedFieldValue !== undefined) {
            return {
                ...acc,
                [fieldName]: normalizedFieldValue,
            };
        }
        return acc;
    }, {});
}
