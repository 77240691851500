import styled from 'styled-components';
import { Picklist } from 'react-rainbow-components';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled(Picklist)`
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    width: 220px;

    input {
        border: 1px solid transparent;
    }
`;
