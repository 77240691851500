import React from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { EvertransitAvatar } from '@nexxtway/react-evertransit';
import AppsMenu from '../../components/AppsMenu';
import FleetSelector from '../../components/FleetSelector';
import {
    Container,
    Title,
    RightContent,
} from './styled';

export default function TopBar(props) {
    const {
        className,
        style,
        title,
        hideFleetSelector,
    } = props;

    return (
        <Container className={className} style={style}>
            <Title data-cy="page-title">
                {title}
            </Title>
            <RightContent>
                <RenderIf isTrue={!hideFleetSelector}>
                    <FleetSelector className="rainbow-m-right_large" />
                </RenderIf>
                <AppsMenu
                    className="rainbow-m-right_large"
                />
                <EvertransitAvatar />
            </RightContent>
        </Container>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideFleetSelector: PropTypes.bool,
};

TopBar.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
    hideFleetSelector: false,
};
