import React from 'react';
import PropTypes from 'prop-types';
import AssignField from '../../../../../components/AssignField';

function getToService(newValue) {
    if (newValue && newValue.label && newValue.name) {
        return {
            label: newValue.label,
            name: newValue.name,
        };
    }
    return null;
}

const AssignServiceSelector = (props) => {
    const {
        value,
        row,
        onChangeServiceMap,
    } = props;
    const { fromService, error } = row;

    const handleChange = (newValue) => {
        onChangeServiceMap({
            fromService,
            toService: getToService(newValue),
        });
    };

    return (
        <AssignField
            value={value}
            options={[]}
            isSingleService
            error={error}
            onChange={handleChange}
        />
    );
};

AssignServiceSelector.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    row: PropTypes.object,
    onChangeServiceMap: PropTypes.func,
};

AssignServiceSelector.defaultProps = {
    value: [],
    row: {},
    onChangeServiceMap: () => {},
};

export default AssignServiceSelector;
