import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../icons/Checkmark';
import Processing from '../icons/Processing';
import Unknown from '../icons/Unknown';
import ErrorSymbol from '../icons/ErrorSymbol';
import {
    Container, Icon,
} from './styled';

const iconMap = {
    pending: Processing,
    inProgress: Processing,
    completed: Checkmark,
    failed: ErrorSymbol,
};

export default function StatusBadge(props) {
    const {
        className,
        style,
        value,
        id,
    } = props;

    const icon = iconMap[value] || Unknown;

    return (
        <Container
            className={className}
            style={style}
            id={id}
            value={value}
        >
            <Icon value={value} as={icon} />
            {value}
        </Container>
    );
}


StatusBadge.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

StatusBadge.defaultProps = {
    className: undefined,
    id: undefined,
    style: undefined,
    value: undefined,
};
