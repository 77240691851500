import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

export default function DateTime(props) {
    const { value, className, style } = props;
    if (value && typeof value.toDate === 'function') {
        const date = value.toDate();
        return (
            <span className={className} style={style}>
                <FormattedDate
                    value={date}
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                />
            </span>
        );
    }
    return '';
}

DateTime.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};

DateTime.defaultProps = {
    className: '',
    style: {},
    value: undefined,
};
