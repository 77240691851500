import React from 'react';
import PropTypes from 'prop-types';

export default function Error(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="18px" height="18px" viewBox="0 0 18 18">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-import-2" transform="translate(-898.000000, -214.000000)" fillRule="nonzero">
                    <g id="cancel" transform="translate(898.000000, 214.000000)">
                        <circle id="Oval" fill="#FE4849" cx="8.98247368" cy="8.98247368" r="8.98247368" />
                        <path d="M17.9649079,8.98247368 C17.9649079,13.9228421 13.9228026,17.9649474 8.98243421,17.9649474 C6.11928947,17.9649474 3.59297368,16.6737237 1.96488158,14.5965395 C3.48067105,15.8316316 5.44559211,16.5614605 7.57890789,16.5614605 C12.5192763,16.5614605 16.5613816,12.5193553 16.5613816,7.57898684 C16.5613816,5.44567105 15.8315526,3.48075 14.5964605,1.96496053 C16.6736842,3.59297368 17.9649079,6.11928947 17.9649079,8.98247368 Z" id="Shape" />
                        <path d="M8.39223059,9.70629167 L8.16387887,6.2895511 C8.12106716,5.62366637 8.1,5.14562481 8.1,4.85569738 C8.1,4.46104382 8.20343906,4.15309754 8.40997849,3.93206174 C8.61678887,3.71028081 8.88951033,3.6 9.22692354,3.6 C9.63600572,3.6 9.90974328,3.74144123 10.0473911,4.0243237 C10.1850389,4.30747712 10.2542015,4.71554321 10.2542015,5.24757359 C10.2542015,5.56161648 10.2374697,5.88073987 10.204548,6.20338575 L9.89761781,9.72024612 C9.86442518,10.1389474 9.79316265,10.459832 9.68342376,10.6836452 C9.57395583,10.9069164 9.39295441,11.0184843 9.14123237,11.0184843 C8.8842266,11.0184843 8.70600252,10.9107776 8.60642464,10.6940771 C8.50630484,10.4780541 8.43483909,10.1485665 8.39223059,9.70629167 Z M9.18404408,14.4 C8.89384569,14.4 8.64036241,14.306451 8.42440712,14.1186756 C8.20797765,13.9311033 8.1,13.6682719 8.1,13.3306555 C8.1,13.0356476 8.20343906,12.7846707 8.40997849,12.5775893 C8.61678887,12.3707789 8.87027215,12.2673399 9.16988641,12.2673399 C9.46970389,12.2673399 9.72474519,12.370508 9.93663608,12.5775893 C10.148256,12.7843997 10.2542015,13.0355798 10.2542015,13.3306555 C10.2542015,13.6634624 10.1472399,13.9248035 9.9327749,14.1151531 C9.71885181,14.304893 9.46943293,14.4 9.18404408,14.4 Z" id="Shape" fill="#FFFFFF" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Error.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Error.defaultProps = {
    style: {},
    className: undefined,
};
