import * as firebase from 'firebase';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const instance = firebase.initializeApp(config);

instance.analytics();

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
    instance.functions().useFunctionsEmulator('http://localhost:5001');
    instance.firestore().settings({
        experimentalForceLongPolling: true,
        host: 'localhost:8080',
        ssl: false,
    });
}
export default instance;
