import styled from 'styled-components';
import CheckmarkCircle from '../../icons/CheckmarkCircle';

export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Card = styled.div`
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    width: 100%;
    height: fit-content;
    padding: 0 52px 24px 52px;
    border-radius: 20px;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_5};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
`;

export const CheckmarkIcon = styled(CheckmarkCircle)`
    width: 100px;
    height: 100px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-top: -50px;
    align-self: center;
`;

export const Title = styled.h1`
    font-size: 30px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-top: 20px;
    text-align: center;
`;

export const Description = styled.p`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-align: center;
    margin-top: 20px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 24px 16px 0 16px;
`;
