import React from 'react';
import PropTypes from 'prop-types';
import { chipsTheme } from '../../../../../theme';
import { ApplicationField, Field } from './styled';

export default function EvertransitField({ value }) {
    return (
        <ApplicationField theme={chipsTheme}>
            <Field variant="brand" label={value} />
        </ApplicationField>
    );
}

EvertransitField.propTypes = {
    value: PropTypes.string.isRequired,
};
