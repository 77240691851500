import { useState, useEffect } from 'react';
import getExportDoc from '../../services/firebase/getExportDoc';

export default function useExportDoc(exportId) {
    const [exportItem, setExportItem] = useState(null);
    useEffect(() => {
        getExportDoc(exportId, setExportItem);
    }, [exportId]);
    return exportItem;
}
