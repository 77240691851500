import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table } from 'react-rainbow-components';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h1`
    font-size: 22px;
    letter-spacing: 0.5;
    color: #616175;
    font-weight: 400;
`;

export const BackButton = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-right: 20px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;

export const MapTable = styled(Table)`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;
