import styled from 'styled-components';

export const Container = styled.span`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Label = styled.h4`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.title};
`;

export const Value = styled.h4`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-weight: 600;

    ${(props) => props.variant === 'error'
        && `
            color: ${props.theme.rainbow.palette.error.main};
        `};
    ${(props) => props.variant === 'success'
        && `
            color: ${props.theme.rainbow.palette.success.main};
        `};
    ${(props) => props.variant === 'brand'
        && `
            color: ${props.theme.rainbow.palette.brand.main};
        `};
`;
