import React from 'react';
import PropTypes from 'prop-types';

export default function Clock(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="28px" height="28px" viewBox="0 0 28 28">
            <g id="data-app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-export-1" transform="translate(-36.000000, -297.000000)" fill="currentColor" fillRule="nonzero">
                    <g id="Group-34" transform="translate(16.000000, 297.000000)">
                        <path d="M34,0 C41.7196,0 48,6.2804 48,14 C48,21.7196 41.7196,28 34,28 C26.2804,28 20,21.7196 20,14 C20,6.2804 26.2804,0 34,0 Z M34,1.05 C26.860299,1.05 21.05,6.86029899 21.05,14 C21.05,21.139701 26.860299,26.95 34,26.95 C41.139701,26.95 46.95,21.139701 46.95,14 C46.95,6.86029899 41.139701,1.05 34,1.05 Z M33.8541667,5.1625 L33.9488572,5.16897288 L34.056281,5.19087172 C34.363341,5.27909231 34.5833333,5.56188829 34.5833333,5.89166667 L34.5821667,14 L39.5708333,14.000875 C39.9736066,14.000875 40.3,14.3271987 40.3,14.7300417 C40.3,15.1328847 39.9736066,15.4592083 39.5708333,15.4592083 L33.8541667,15.4592083 C33.4513934,15.4592083 33.125,15.1328847 33.125,14.7300417 L33.125,5.89166667 C33.125,5.48882367 33.4513934,5.1625 33.8541667,5.1625 Z" id="clock" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Clock.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Clock.defaultProps = {
    style: {},
    className: undefined,
};
