import styled from 'styled-components';

export const Container = styled.header`
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 30px;
    font-weight: 200;
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
`;
