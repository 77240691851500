import React from 'react';
import PropTypes from 'prop-types';

export default function ExportFilled(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="26px" height="32px" viewBox="0 0 26 32">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M23.1865811,10.9709717 C24.6222162,10.9709717 25.8232985,12.3023257 25.9826569,14.0313216 L25.995708,14.2180502 L26,14.4048187 L26,28.566153 C26,30.318381 24.9091953,31.7843315 23.4925947,31.9788323 L23.3396042,31.9947616 L23.1865811,32 L2.81341892,32 C1.37778375,32 0.176701458,30.668646 0.0173431091,28.9396501 L0.00429195864,28.7529215 L1.70530257e-13,28.566153 L1.70530257e-13,14.4048187 C1.70530257e-13,12.6525907 1.09080472,11.1866402 2.50740535,10.9921394 L2.6603958,10.9762101 L2.81341892,10.9709717 L10.802427,10.9762101 L10.802427,18.1792992 C10.802427,19.2838687 11.6978575,20.1792992 12.802427,20.1792992 L12.8048001,20.1792978 L13.2197507,20.1788054 C14.3233929,20.1774959 15.2173777,19.2824497 15.2173777,18.1788068 L15.2173777,10.9709717 L23.1865811,10.9709717 Z M13.0095329,2.13162821e-14 C13.1620591,2.13162821e-14 13.3145853,0.0608494834 13.4366063,0.18254845 L18.8360343,5.56772773 C19.0800763,5.81112567 19.0800763,6.17622257 18.8360343,6.4196205 C18.5919924,6.66301843 18.2259295,6.66301843 17.9818875,6.4196205 L13.6196378,2.06888244 L13.6196378,18.254845 C13.6196378,18.5895172 13.3450906,18.8633399 13.0095329,18.8633399 C12.6739752,18.8633399 12.399428,18.5895172 12.399428,18.254845 L12.399428,2.06888244 L8.03717827,6.4196205 C7.79313632,6.66301843 7.4270734,6.66301843 7.18303146,6.4196205 C6.93898951,6.17622257 6.93898951,5.81112567 7.18303146,5.56772773 L12.5824595,0.18254845 C12.7044805,0.0608494834 12.8570067,2.13162821e-14 13.0095329,2.13162821e-14 Z" id="export-filled" fill="currentColor" fillRule="nonzero" />
            </g>
        </svg>
    );
}

ExportFilled.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

ExportFilled.defaultProps = {
    style: {},
    className: undefined,
};
