import React from 'react';
import PropTypes from 'prop-types';
import useFileData from '../../hooks/useFileData';
import AssignField from '../AssignField';
import getParsedMappingFromFieldValue from '../../services/helpers/getParsedMappingFromFieldValue';

const AssignSelector = (props) => {
    const {
        value,
        row,
        onChange,
    } = props;
    const [, headers] = useFileData();
    const { name: toField, error } = row;
    const parsedValue = getParsedMappingFromFieldValue(headers, value);

    if (row && row.hasNestedFields) {
        return null;
    }
    const isSingleService = row.typeSelected === 'single-service';

    const handleChange = (newValue) => {
        if (isSingleService) {
            if (newValue && newValue.label && newValue.name) {
                onChange({
                    fromFields: [{
                        label: newValue.label,
                        name: newValue.name,
                    }],
                    toField,
                });
            } else {
                onChange({
                    fromFields: [],
                    toField,
                });
            }
        } else {
            onChange({
                fromFields: newValue,
                toField,
            });
        }
    };

    return (
        <AssignField
            value={parsedValue}
            options={headers}
            isSingleService={isSingleService}
            error={error}
            onChange={handleChange}
        />
    );
};

AssignSelector.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    row: PropTypes.object,
    onChange: PropTypes.func,
};

AssignSelector.defaultProps = {
    value: [],
    row: {},
    onChange: () => {},
};

export default AssignSelector;
