import { showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import getRidesSample from '../../services/firebase/getRidesSample';

export default async function fetchRidesSample(params) {
    const {
        groupId, dateRange, mapping, statuses,
    } = params;
    try {
        showAppSpinner();
        const { data } = await getRidesSample({
            groupId,
            startDate: dateRange[0].getTime(),
            endDate: dateRange[1].getTime(),
            mapping,
            statuses,
        });
        hideAppSpinner();
        return data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        hideAppSpinner();
        return { rides: [], total: 0 };
    }
}
