import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, RightContent, LeftContent,
} from './styled';

export default function Header(props) {
    const {
        leftContent,
        rightContent,
        className,
        style,
    } = props;

    return (
        <Container className={className} style={style}>
            <LeftContent>
                {leftContent}
            </LeftContent>
            <RightContent>
                {rightContent}
            </RightContent>
        </Container>
    );
}


Header.propTypes = {
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
};

Header.defaultProps = {
    leftContent: undefined,
    rightContent: undefined,
    className: undefined,
    style: undefined,
};
