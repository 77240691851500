import React, { useEffect } from 'react';
import { Button, Table, Column } from 'react-rainbow-components';
import { useParams, useHistory } from 'react-router-dom';
import { showAppSpinner, hideAppSpinner, showAppMessage } from '@rainbow-modules/app';
import Header from '../../../components/Header';
import ArrowBack from '../../../components/icons/ArrowBack';
import {
    Container,
    Title,
    BackButton,
    ErrorMessage,
} from './styled';
import useFileData from '../../../hooks/useFileData';
import useImportStats from '../../../hooks/useImportStats';
import useLocalMapping from '../../../hooks/useLocalMapping';
import importDataToFirestore from '../../../services/firebase/importData';
import getPreviewTableData from '../../../services/helpers/getPreviewTableData';
import getMappedData from '../../../services/helpers/getMappedData';
import getPreviewTableColumnInfo from '../../../services/helpers/getPreviewTableColumnInfo';
import getDataWithGroupAttached from '../../../services/helpers/getDataWithGroupAttached';
import getValidatedData from '../../../services/helpers/getValidatedData';
import isSingleService from '../../../services/helpers/isSingleService';
import learnImportMapping from '../../../services/firebase/learnImportMapping';

const computeImportStats = (validData = [], invalidData = []) => {
    const validCount = validData.length;
    const invalidCount = invalidData.length;
    const total = validCount + invalidCount;
    return {
        validCount,
        invalidCount,
        total,
    };
};

function Preview() {
    const { groupId } = useParams();
    const csvFile = useFileData();
    const fileData = Array.isArray(csvFile) && csvFile[0];
    const [mapping] = useLocalMapping(groupId, csvFile);
    const parsedFileData = fileData || [];
    const data = getMappedData(parsedFileData, mapping);
    const { validData, invalidData } = getValidatedData(data, mapping);
    const hasNoValidData = validData.length === 0;
    const [, setImportStats] = useImportStats();
    const importStats = computeImportStats(validData, invalidData);
    setImportStats(importStats);
    const tableInfo = getPreviewTableColumnInfo(mapping);
    const history = useHistory();
    const { idFieldName, tableData } = getPreviewTableData(validData);

    useEffect(() => {
        if (!fileData || !mapping) {
            history.push(`/app/${groupId}/import`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onImportData = async () => {
        showAppSpinner();
        const fullData = getDataWithGroupAttached({
            data: validData,
            group: groupId,
        });
        try {
            await Promise.all([
                importDataToFirestore(fullData),
                learnImportMapping({ groupId, mapping }),
            ]);
            hideAppSpinner();
            history.push(`/app/${groupId}/import/done`);
            sessionStorage.clear();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            showAppMessage({
                message: 'Something was wrong with the import. Please, try again.',
                variant: 'error',
            });
        }
    };

    const goBack = () => {
        if (isSingleService(mapping)) {
            return history.push(`/app/${groupId}/import/fieldMapping`);
        }
        return history.goBack();
    };

    return (
        <Container>
            <Header
                className="rainbow-m-horizontal_small"
                leftContent={(
                    <>
                        <BackButton data-cy="import-preview-back-button" onClick={goBack}>
                            <ArrowBack />
                        </BackButton>
                        <Title>Preview</Title>
                    </>
                )}
                rightContent={(
                    <>
                        <Button
                            label="Import Now"
                            variant="brand"
                            shaded
                            id="import-now-button"
                            disabled={hasNoValidData}
                            onClick={onImportData}
                        />
                    </>
                )}
            />
            <Table
                className="rainbow-m-bottom_large rainbow-m-top_small"
                keyField={idFieldName}
                id="preview-table"
                data={tableData}
                showRowNumberColumn
                minColumnWidth="240"
                emptyIcon={<ErrorMessage />}
                emptyTitle="Invalid Data"
                emptyDescription="Our robots cannot import your data, you must review the data in its CSV file."
            >
                {tableInfo.map(({ header, field }, index) => {
                    const key = `column-${index}`;
                    return (
                        <Column
                            key={key}
                            header={header}
                            field={field}
                        />
                    );
                })}
            </Table>
        </Container>
    );
}

export default Preview;
