import React from 'react';
import PropTypes from 'prop-types';

export default function ErrorSymbol(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="3px" height="12px" viewBox="0 0 3 12">
            <g id="data-app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-22" transform="translate(-9.000000, -16.000000)" fill="currentColor" fillRule="nonzero">
                    <g id="cancel-copy" transform="translate(0.000000, 12.000000)">
                        <path d="M9.32470066,10.7847685 L9.07097652,6.98839011 C9.02340795,6.24851819 9,5.7173609 9,5.39521931 C9,4.95671536 9.11493229,4.61455282 9.34442054,4.36895749 C9.57420986,4.12253423 9.8772337,4 10.2521373,4 C10.706673,4 11.0108259,4.15715692 11.1637679,4.47147077 C11.3167098,4.78608569 11.3935572,5.23949245 11.3935572,5.83063732 C11.3935572,6.17957386 11.3749663,6.53415541 11.3383867,6.89265083 L10.9973531,10.8002735 C10.9604724,11.2654971 10.8812918,11.6220356 10.7593597,11.8707169 C10.6377287,12.118796 10.436616,12.2427603 10.1569249,12.2427603 C9.87136289,12.2427603 9.67333613,12.1230862 9.56269405,11.8823079 C9.45144983,11.6422823 9.37204343,11.276185 9.32470066,10.7847685 Z M10.2044934,16 C9.88205077,16 9.60040268,15.8960567 9.36045235,15.6874173 C9.11997516,15.4790037 9,15.1869688 9,14.8118395 C9,14.4840529 9.11493229,14.2051896 9.34442054,13.9750993 C9.57420986,13.7453099 9.85585795,13.6303777 10.1887627,13.6303777 C10.5218932,13.6303777 10.8052724,13.7450089 11.0407068,13.9750993 C11.27584,14.2048886 11.3935572,14.4839776 11.3935572,14.8118395 C11.3935572,15.1816249 11.274711,15.4720039 11.0364166,15.6835034 C10.7987242,15.8943255 10.5215921,16 10.2044934,16 Z" id="Shape" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

ErrorSymbol.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

ErrorSymbol.defaultProps = {
    style: {},
    className: undefined,
};
