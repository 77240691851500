import styled from 'styled-components';
import { MenuItem } from 'react-rainbow-components';
import App from '../../icons/App';
import EvertransitLogo from '../../icons/EvertransitLogo';

export const AppIcon = styled(App)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const MenuContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 0 8px;
`;

export const StyledMenuItem = styled(MenuItem)`
    border-radius: 8px;

    &:hover {
        border-radius: 8px;
    }
`;

export const Item = styled.a`
    background: transparent;
    background-clip: border-box;
    border: 1px solid transparent;
    padding: 0;
    height: 100%;
    width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover, :active {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
        text-decoration: none;
    }
`;

export const ItemLabel = styled.h1`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-top: 4px;
`;

export const SelectAppIcon = styled(EvertransitLogo)`
    width: 48px;
    height: 48px;
`;
