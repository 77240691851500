import React from 'react';
import PropTypes from 'prop-types';
import {
    Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import Import from './import';
import Export from './export';
import History from './history';
import AppSidebar from '../layouts/AppSidebar';
import TopBar from '../layouts/TopBar';
import getEvertransitGroupId from '../services/helpers/getEvertransitGroupId';
import { StyledContent, StyledAppContainer } from './styled';

function isIncludedInUrlPath(location, match) {
    return new RegExp(`\\b${match}\\b`).test(location && location.pathname);
}

function getTitle(location) {
    if (isIncludedInUrlPath(location, 'export')) {
        return 'Export Rides';
    }
    if (isIncludedInUrlPath(location, 'history')) {
        return 'History';
    }
    return 'Import Rides';
}

export default function AuthRoutes(props) {
    const {
        className,
        style,
    } = props;
    const groupId = getEvertransitGroupId();
    const location = useLocation();

    return (
        <StyledAppContainer className={className} style={style}>
            <AppSidebar />
            <StyledContent>
                <TopBar title={getTitle(location)} hideFleetSelector={isIncludedInUrlPath(location, 'preview')} />
                <Switch>
                    <Route path="/app/:groupId/import" component={Import} />
                    <Route path="/app/:groupId/export" component={Export} />
                    <Route path="/app/:groupId/history" component={History} />
                    <Redirect from="/" to={`/app/${groupId}/import`} />
                </Switch>
            </StyledContent>
        </StyledAppContainer>
    );
}

AuthRoutes.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

AuthRoutes.defaultProps = {
    className: undefined,
    style: undefined,
};
