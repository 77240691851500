function getValueFromField(fromFields, fromObject, separator = ' ') {
    if (fromFields instanceof Array) {
        return fromFields.reduce((acc, currentField, index) => {
            const isFirst = index === 0;
            if (fromObject[currentField]) {
                if (isFirst) return `${fromObject[currentField]}`;
                return `${acc}${separator}${fromObject[currentField]}`;
            }
            return acc;
        }, '');
    }
    return fromObject[fromFields];
}

function getMappedValue(fieldMapping, fromObject) {
    const { typeSelected, fromFields } = fieldMapping;
    if (typeSelected === 'full-address') {
        const value = getValueFromField(fromFields, fromObject);
        if (value) {
            return {
                type: 'full-address',
                value,
            };
        }
        return undefined;
    }
    if (typeSelected === 'address-elements') {
        const { type } = fieldMapping;
        const selectedTypeValue = type.find((typeValue) => typeValue.type === 'address-elements');
        const { subFields: subFieldsMapping } = selectedTypeValue;
        // eslint-disable-next-line no-use-before-define
        const subFieldsMapped = getTransformedObject(fromObject, subFieldsMapping);
        return {
            type: 'address-elements',
            ...subFieldsMapped,
        };
    }
    if (typeSelected === 'single-service') {
        return fromFields[0];
    }
    if (typeSelected === 'multi-service') {
        const { mapping: serviceMapping } = fieldMapping;
        const valueFromFields = getValueFromField(fromFields, fromObject, ' - ');
        return serviceMapping[valueFromFields];
    }
    return getValueFromField(fromFields, fromObject);
}

function getTransformedObject(fromObject, mapping) {
    return Object.keys(mapping).reduce((toObject, currentFieldName) => {
        const fieldMapping = mapping[currentFieldName];

        const mappedValue = getMappedValue(fieldMapping, fromObject);
        if (mappedValue) {
            return {
                ...toObject,
                [currentFieldName]: mappedValue,
            };
        }
        return toObject;
    }, {});
}

const getMappedData = (data, mapping) => {
    if (data.length > 0 && mapping) {
        return data.map(
            (fromObject) => getTransformedObject(fromObject, mapping),
        );
    }
    return [];
};

export default getMappedData;
