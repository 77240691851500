export default function getNormalizedMapping(mapping) {
    return mapping.map((field) => {
        const hasDot = field.fieldName.indexOf('.') !== -1;
        if (hasDot) {
            return {
                ...field,
                normalizedField: field.fieldName.replace('.', ' '),
            };
        }
        return {
            ...field,
            normalizedField: field.fieldName,
        };
    });
}
