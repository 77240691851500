import styled from 'styled-components';
import Download from '../../icons/Download';

export const DescriptionContainer = styled.div`
    margin: 0;
    font-size: 0.875rem;
    font-weight: 300;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    padding: 0.25rem 0 0 0;
`;

export const DownloadIcon = styled(Download)`
    margin-right: 8px;
`;

export const Link = styled.a`
    display: flex;
    align-items: center;
    margin-top: 4px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};

    &:hover, &:active, &:focus {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;
