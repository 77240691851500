import styled from 'styled-components';
import { Picklist, HelpText } from 'react-rainbow-components';

export const DateSelectorContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const DateSelector = styled(Picklist)`
    width: 230px;
`;

export const ErrorIcon = styled(HelpText)`
    position: absolute;
    left: -36px;
`;
