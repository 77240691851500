import styled from 'styled-components';
import { Chip, ButtonIcon } from 'react-rainbow-components';
import ErrorMessage from '../../ErrorMessage';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 0;
`;

export const Placeholder = styled.h1`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-transform: capitalize;
`;

export const FieldChip = styled(Chip)`
    margin: 4px 16px 4px 0;

    ${(props) => props.isRequired
        && `
            margin: 4px 16px 4px 0;
        `};
`;

export const Cell = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
`;

export const SelectContainer = styled.div`
    width: 90%;
    height: 100%;
    position: relative;
    ${(props) => !props.isSingleService && `
        ::before {
            content: '';
            position: absolute;
            display: block;
            top: 35%;
            right: 0.75rem;
            pointer-events: none;
            width: 0.45rem;
            height: 0.45rem;
            border-style: solid;
            border-width: 0.15em 0.15em 0 0;
            transform: rotate(135deg);
            color: ${props.theme.rainbow.palette.brand.main};
            z-index: 1000;
        }
    `}
`;

export const Select = styled.select`
    appearance: none;
    line-height: 1.5rem;
    height: 2rem;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 1rem;
    padding: 0 0.675rem;
    background-color: white;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 14px;
    transition: all 0.1s linear;
    position: relative;
    width: 100%;

    &:focus,
    &:active,
    &:visited {
        outline: 0;
        border: 1px solid ${(props) => props.theme.rainbow.palette.brand.main};
        box-shadow: 0 0 2px ${(props) => props.theme.rainbow.palette.brand.main};
    }
`;

export const PlusButton = styled(ButtonIcon)`
    flex-shrink: 0;
    margin-right: 20px;
`;

export const ChipListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
    position: absolute;
    right: -10px;
    bottom: 8px;
`;
