import styled from 'styled-components';
import Error from '../../icons/Error';
import ErrorBorder from '../../icons/ErrorBorder';

export const ErrorButton = styled.button`
    background-color: transparent;
    border: none;
    width: fit-content;
    height: fit-content;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    :focus, :hover {
        outline: none;
        box-shadow: ${(props) => props.theme.rainbow.shadows.error};
    }
`;

export const ErrorIcon = styled(Error)`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
`;

export const ChipListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ErrorContent = styled.div`
    background-color: white;
    padding: 8px 16px 8px 12px;
    border-radius: 20px;
    width: 280px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    transition: opacity 0.1s linear, visibility 0.1s linear;
    border: 1px solid #e3e5ed;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
`;

export const ErrorTitle = styled.h1`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.error.main};
    margin-left: 8px;
`;

export const ErrorText = styled.p`
    color: #677489;
    font-size: 14px;
    margin-left: 4px;
    letter-spacing: 0.5;
    margin-top: 6px;
`;

export const ErrorMessageIcon = styled(ErrorBorder)`
    width: 22px;
    height: 22px;
`;
