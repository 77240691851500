import React from 'react';
import { EvertransitApp } from '@nexxtway/react-evertransit';
import firebase from './firebase';
import AuthRoutes from './pages';
import theme from './theme';

function App() {
    return (
        <EvertransitApp
            app={firebase}
            theme={theme}
        >
            <AuthRoutes />
        </EvertransitApp>
    );
}

export default App;
