import shortid from 'shortid';

const getPreviewTableData = (data, elementsCount = 10) => {
    const idFieldName = shortid.generate();
    const tableData = data.slice(0, elementsCount).map((current, index) => {
        const currentId = `row_${index}`;
        return {
            [idFieldName]: currentId,
            ...current,
        };
    });
    return {
        idFieldName,
        tableData,
    };
};

export default getPreviewTableData;
