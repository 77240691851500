import React from 'react';
import PropTypes from 'prop-types';
import { PicklistOption } from 'react-rainbow-components';

export default function TemplateOptions({ templates }) {
    return templates.map((item) => {
        const { id, name } = item;
        return <PicklistOption key={id} name={id} label={name} value={item} />;
    });
}

TemplateOptions.propTypes = {
    templates: PropTypes.array,
};

TemplateOptions.defaultProps = {
    templates: [],
};
