import React from 'react';
import {
    FieldChip,
} from './styled';

function getLabel(item) {
    if (typeof item === 'string') {
        return item;
    }
    return (item && item.label) || '';
}

const ChipsList = ({ list, onChange, variant }) => list.map((item, index) => {
    const key = `chip_${index}`;
    return (
        <FieldChip
            key={key}
            label={getLabel(item)}
            variant={variant}
            onDelete={() => onChange(
                list.filter(
                    (element) => element !== item,
                ),
            )}
        />
    );
});

export default ChipsList;
