import styled from 'styled-components';
import { Application, Chip } from 'react-rainbow-components';

export const ApplicationField = styled(Application)`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const Field = styled(Chip)`
    margin: 4px 16px 4px 10px;
    flex-grow: 1;
    margin-left: 0;
`;
