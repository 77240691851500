import firebase from '../../firebase';
import getCurrentUserId from './getCurrentUserId';

export default async function getExportDoc(exportId, onCallback) {
    const uid = getCurrentUserId();
    const ref = firebase.firestore().doc(`users/${uid}/exports/${exportId}`);
    ref.onSnapshot((doc) => {
        if (doc.exists) {
            return onCallback(doc.data());
        }
        return onCallback(null);
    });
}
