import React from 'react';
import PropTypes from 'prop-types';
import TextIcon from '../icons/Text';
import NumberIcon from '../icons/Number';
import Location from '../icons/Location';
import Calendar from '../icons/Calendar';
import Unknown from '../icons/Unknown';
import PhoneCircleFilled from '../icons/PhoneCircleFilled';
import Currency from '../icons/Currency';
import Car from '../icons/Car';

const iconMap = {
    text: TextIcon,
    email: TextIcon,
    number: NumberIcon,
    address: Location,
    date: Calendar,
    'phone-number': PhoneCircleFilled,
    currency: Currency,
};

function isAddressType(value) {
    return value.every((item) => /address/.test(item.type));
}

function isServiceType(value) {
    return value.every((item) => /service/.test(item.type));
}

export default function Icon({ value }) {
    const isArrayType = Array.isArray(value);
    if (isArrayType) {
        if (isAddressType(value)) {
            return <Location />;
        }
        if (isServiceType(value)) {
            return <Car />;
        }
    }
    if (typeof value === 'string') {
        const IconComponent = iconMap[value] || Unknown;
        return <IconComponent />;
    }
    return <Unknown />;
}

Icon.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Icon.defaultProps = {
    value: undefined,
};
