import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import InternalOverlay from 'react-rainbow-components/components/InternalOverlay';
import {
    ErrorButton,
    ErrorIcon,
    ErrorContent,
    ErrorText,
    Header,
    ErrorTitle,
    ErrorMessageIcon,
} from './styled';

const ErrorMessage = (props) => {
    const {
        error,
        className,
        style,
        dataCy,
    } = props;
    const [showError, setShowError] = useState(false);
    const iconRef = useRef();

    return (
        <>
            <ErrorButton
                onMouseEnter={() => setShowError(true)}
                onMouseLeave={() => setShowError(false)}
                ref={iconRef}
                className={className}
                style={style}
                error={!!error}
                data-cy={dataCy}
            >
                <ErrorIcon />
            </ErrorButton>
            <InternalOverlay
                isVisible={showError}
                render={() => (
                    <ErrorContent>
                        <Header>
                            <ErrorMessageIcon />
                            <ErrorTitle>
                                Select a field
                            </ErrorTitle>
                        </Header>
                        <ErrorText>
                            {error}
                        </ErrorText>
                    </ErrorContent>
                )}
                triggerElementRef={() => iconRef}
            />
        </>
    );
};

ErrorMessage.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.string,
    dataCy: PropTypes.string,
};

ErrorMessage.defaultProps = {
    className: undefined,
    style: undefined,
    error: undefined,
    dataCy: undefined,
};

export default ErrorMessage;
