import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DoneCard from '../../../../components/DoneCard';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const Card = styled(DoneCard)`
    max-width: 500px;
`;

export const StartButton = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    font-size: 16px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;
