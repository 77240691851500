import React from 'react';
import PropTypes from 'prop-types';
import {
    PicklistOption,
    MenuDivider,
} from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import useServiceLevels from '../../hooks/useServiceLevels';
import { Container, ServiceSelector, ErrorIcon } from './styled';

function Options({ serviceLevels = [] }) {
    return serviceLevels
        .map((service, index) => {
            if (service === 'divider') {
                const key = `divider-${index}`;
                return <MenuDivider key={key} />;
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <PicklistOption {...service} />;
        });
}

export default function ServiceLevelSelector(props) {
    const {
        className, style, value, onChange, error, picklistRef,
    } = props;
    const [serviceLevels, isLoading] = useServiceLevels();

    return (
        <Container>
            <RenderIf isTrue={!!error}>
                <ErrorIcon
                    error={error}
                    dataCy="service-error-button"
                />
            </RenderIf>
            <ServiceSelector
                placeholder="Select Service"
                variant="shaded"
                enableSearch={!isLoading}
                isLoading={isLoading}
                value={value}
                onChange={onChange}
                className={className}
                style={style}
                id="service-selector"
                ref={picklistRef}
            >
                <Options serviceLevels={serviceLevels} />
            </ServiceSelector>
        </Container>
    );
}

ServiceLevelSelector.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.string,
    picklistRef: PropTypes.object,
};

ServiceLevelSelector.defaultProps = {
    className: undefined,
    style: undefined,
    value: undefined,
    onChange: () => {},
    error: undefined,
    picklistRef: undefined,
};
