import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import ServiceLevelSelector from '../ServiceLevelSelector';
import OptionList from './OptionList';
import {
    SelectContainer,
    Select,
} from './styled';

const SelectOptions = ({ onChange, options, isSingleService }) => {
    const ref = useRef();
    useEffect(() => {
        ref.current.focus();
    }, []);

    return (
        <SelectContainer isSingleService={isSingleService}>
            <RenderIf isTrue={isSingleService}>
                <ServiceLevelSelector picklistRef={ref} onChange={onChange} />
            </RenderIf>
            <RenderIf isTrue={!isSingleService}>
                <Select data-cy="assign-field-select" defaultValue="defaultOption" onChange={onChange} ref={ref}>
                    <option key="option_default" value="defaultOption">
                        Select an option
                    </option>
                    <OptionList list={options} />
                </Select>
            </RenderIf>
        </SelectContainer>
    );
};

SelectOptions.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    isSingleService: PropTypes.bool,
};

SelectOptions.defaultProps = {
    onChange: () => {},
    options: [],
    isSingleService: false,
};

export default SelectOptions;
