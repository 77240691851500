import React from 'react';
import PropTypes from 'prop-types';
import { PicklistOption, MenuDivider } from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import moment from 'moment';
import { DateSelectorContainer, DateSelector, ErrorIcon } from './styled';

function getTodayRange() {
    return [
        new Date(new Date().setHours(0, 0, 0, 0)),
        new Date(new Date().setHours(23, 59, 59, 999)),
    ];
}

function getYesterdayRange() {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return [
        new Date(today.setHours(0, 0, 0, 0)),
        new Date(today.setHours(23, 59, 59, 999)),
    ];
}

function getThisWeekRange() {
    const startDate = moment().startOf('week').toDate();
    const endDate = moment().endOf('week').toDate();
    return [startDate, endDate];
}

function getLastWeekRange() {
    const startDate = moment().subtract(1, 'week').startOf('week').toDate();
    const endDate = moment().subtract(1, 'week').endOf('week').toDate();
    return [startDate, endDate];
}

function getThisMonthRange() {
    const startDate = moment().startOf('month').toDate();
    const endDate = moment().endOf('month').toDate();
    return [startDate, endDate];
}

function getLastMonthRange() {
    const startDate = moment().subtract(1, 'month').startOf('month').toDate();
    const endDate = moment().subtract(1, 'month').endOf('month').toDate();
    return [startDate, endDate];
}

export default function DateRangeSelector(props) {
    const { value, onChange, error } = props;
    const todayRange = getTodayRange();
    const yesterdayRange = getYesterdayRange();
    const thisWeekRange = getThisWeekRange();
    const lastWeekRange = getLastWeekRange();
    const thisMonthRange = getThisMonthRange();
    const lastMonthRange = getLastMonthRange();

    return (
        <DateSelectorContainer data-cy="date-selector-container">
            <RenderIf isTrue={!!error}>
                <ErrorIcon
                    variant="error"
                    title="Select date range"
                    text={error}
                />
            </RenderIf>
            <DateSelector
                label="Select date range"
                hideLabel
                placeholder="Select date range"
                className="rainbow-m-right_large"
                variant="shaded"
                onChange={onChange}
                value={value}
                id="date-selector"
            >
                <PicklistOption name="custom" label="Custom" />
                <MenuDivider />
                <PicklistOption name="today" label="Today" value={todayRange} />
                <PicklistOption name="yesterday" label="Yesterday" value={yesterdayRange} />
                <PicklistOption name="this-week" label="This Week" value={thisWeekRange} />
                <PicklistOption name="last-week" label="Last Week" value={lastWeekRange} />
                <PicklistOption name="this-month" label="This Month" value={thisMonthRange} />
                <PicklistOption name="last-month" label="Last Month" value={lastMonthRange} />
            </DateSelector>
        </DateSelectorContainer>
    );
}

DateRangeSelector.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.string,
};

DateRangeSelector.defaultProps = {
    value: undefined,
    onChange: () => {},
    error: undefined,
};
