import React from 'react';
import PropTypes from 'prop-types';

export default function ChevronDown(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="12px" height="7px" viewBox="0 0 12 7">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-export-1-assign-1.2" transform="translate(-586.000000, -222.000000)" fill="#00AB8E" fillRule="nonzero">
                    <g id="Group" transform="translate(86.000000, 160.000000)">
                        <g id="Group-6" transform="translate(371.000000, 53.000000)">
                            <g id="Group-27-Copy-2" transform="translate(123.000000, 0.000000)">
                                <g id="down-thin-chevron" transform="translate(12.250000, 12.750000) rotate(-270.000000) translate(-12.250000, -12.750000) translate(8.250000, 6.750000)">
                                    <path d="M0.977877771,1.14320689 C0.723392657,1.47716519 0.748841168,1.95620739 1.0542233,2.26158953 L5.052,6.259 L1.05422925,10.2574393 C0.721100253,10.5905683 0.721100253,11.1299478 1.0542233,11.4630708 C1.21600185,11.6252348 1.43280727,11.7131096 1.65734492,11.7131096 C1.88196498,11.7131096 2.0986358,11.6250491 2.26042451,11.4627193 L6.86070722,6.86215633 C7.19384598,6.52949426 7.19384598,5.98968029 6.86070183,5.65653614 L2.26015071,1.05598503 C1.9270825,0.722837382 1.38734987,0.722837382 1.0542233,1.05596395 L0.977877771,1.14320689 Z" id="Path" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

ChevronDown.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

ChevronDown.defaultProps = {
    style: {},
    className: undefined,
};
