import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Header from '../../../../components/Header';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const HeaderContainer = styled(Header)`
    padding: 0 48px;
`;

export const LeftContent = styled.div`
    display: flex;
`;

export const Title = styled.h1`
    font-size: 22px;
    letter-spacing: 0.5;
    color: #616175;
    font-weight: 400;
`;

export const Description = styled.h2`
    font-size: 16px;
    letter-spacing: 0.5;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-weight: 400;
`;

export const BackButton = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-right: 20px;
    margin-top: 8px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;
