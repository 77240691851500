import React from 'react';
import PropTypes from 'prop-types';

export default function Location(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="16px" height="16px" viewBox="0 0 16 16">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-import-2" transform="translate(-403.000000, -419.000000)" fill="#AFAFC3" fillRule="nonzero">
                    <g id="Group-23" transform="translate(86.000000, 158.000000)">
                        <g id="Group-12-Copy-5" transform="translate(0.000000, 249.000000)">
                            <g id="Group-18" transform="translate(317.000000, 9.000000)">
                                <path d="M12.0625,3 C12.4076875,3 12.6875,3.2798125 12.6875,3.625 L12.6875,4.25 L13.5,4.25 C14.8785,4.25 16,5.3715 16,6.75 L16,13.375 C16,13.7201875 15.7201875,14 15.375,14 C15.0298125,14 14.75,13.7201875 14.75,13.375 L14.75,6.75 C14.75,6.06075 14.18925,5.5 13.5,5.5 L12.6875,5.5 L12.6875,6.125 C12.6875,6.4701875 12.4076875,6.75 12.0625,6.75 C11.7173125,6.75 11.4375,6.4701875 11.4375,6.125 L11.4375,5.5 L8.59375,5.5 L8.59375,6.125 C8.59375,6.4701875 8.3139375,6.75 7.96875,6.75 C7.6235625,6.75 7.34375,6.4701875 7.34375,6.125 L7.34375,5.5 L4.53125,5.5 L4.53125,6.125 C4.53125,6.4701875 4.2514375,6.75 3.90625,6.75 C3.5610625,6.75 3.28125,6.4701875 3.28125,6.125 L3.28125,5.5 L2.5,5.5 C1.81075,5.5 1.25,6.06075 1.25,6.75 L1.25,16.5 C1.25,17.18925 1.81075,17.75 2.5,17.75 L13.5,17.75 C14.18925,17.75 14.75,17.18925 14.75,16.5 C14.75,16.1548125 15.0298125,15.875 15.375,15.875 C15.7201875,15.875 16,16.1548125 16,16.5 C16,17.8785 14.8785,19 13.5,19 L2.5,19 C1.1215,19 0,17.8785 0,16.5 L0,6.75 C0,5.3715 1.1215,4.25 2.5,4.25 L3.28125,4.25 L3.28125,3.625 C3.28125,3.2798125 3.5610625,3 3.90625,3 C4.2514375,3 4.53125,3.2798125 4.53125,3.625 L4.53125,4.25 L7.34375,4.25 L7.34375,3.625 C7.34375,3.2798125 7.6235625,3 7.96875,3 C8.3139375,3 8.59375,3.2798125 8.59375,3.625 L8.59375,4.25 L11.4375,4.25 L11.4375,3.625 C11.4375,3.2798125 11.7173125,3 12.0625,3 Z M11,12 C11.5522847,12 12,12.4477153 12,13 L12,14 C12,14.5522847 11.5522847,15 11,15 L10,15 C9.44771525,15 9,14.5522847 9,14 L9,13 C9,12.4477153 9.44771525,12 10,12 L11,12 Z" id="calendar" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Location.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Location.defaultProps = {
    style: {},
    className: undefined,
};
