import { useState } from 'react';
import shortid from 'shortid';
import useLocalData from '../useLocalData';
import serializeDataToSessionStorage from '../../services/helpers/serializeDataToSessionStorage';

const getValidKey = (key) => {
    if (typeof key === 'string') {
        return key;
    }

    return shortid.generate();
};

export default function usePersistentState(sessionStorageKey, initialValue) {
    const validKey = getValidKey(sessionStorageKey);
    const sessionData = useLocalData(validKey);
    const [persistentState, setState] = useState(sessionData || initialValue);
    const setPersistentState = (newValue) => {
        setState(newValue);
        if (newValue === undefined) {
            sessionStorage.removeItem(validKey);
        } else {
            serializeDataToSessionStorage(validKey, newValue);
        }
    };
    return [persistentState, setPersistentState];
}
