import styled from 'styled-components';
import { Application, Chip } from 'react-rainbow-components';

export const ApplicationContainer = styled(Application)`
    display: flex;
    align-items: center;
    height: 100%;
    ${(props) => props.isChild
        && `
            margin-left: 30px;
        `};
`;

export const FieldChip = styled(Chip)`
    margin: 4px 16px 4px 10px;
    flex-grow: 1;

    ${(props) => props.isRequired
        && `
            margin-left: 0;
        `};

    ${(props) => props.showRequiredIfEmpty
        && `
            margin-left: 4px;
        `};
`;

export const RequiredContainer = styled.span`
    align-self: flex-start;
    height: 20px;

    > abbr {
        height: 20px;
    }
`;

export const RequiredIfEmptyType = styled.abbr`
    border: 0;
    text-decoration: none;
    cursor: help;
    height: 5px;
    width: 5px;
    background-color: ${(props) => props.theme.rainbow.palette.warning.main};
    border-radius: 50%;
    margin-bottom: 8px;
`;
