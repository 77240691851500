export default function getUpdatedTemplateMapping({ template, key, value }) {
    return {
        ...template,
        value: {
            ...template.value,
            mapping: {
                ...template.value.mapping,
                [key]: value,
            },
        },
    };
}
