import {
    useState, useEffect, useCallback,
} from 'react';
import { showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import useLocalData from '../useLocalData';
import serializeDataToSessionStorage from '../../services/helpers/serializeDataToSessionStorage';
import fetchLearnedMapping from '../../services/firebase/fetchLearnedMapping';

const useLocalMapping = (groupId, csvFile) => {
    const storageKey = `mapping-${groupId}`;
    const mappingFromSessionStorage = useLocalData(storageKey);
    const [mappingState, setMappingState] = useState(mappingFromSessionStorage);

    const storeMapping = useCallback((updatedMapping) => {
        setMappingState(updatedMapping);
        serializeDataToSessionStorage(storageKey, updatedMapping);
    }, [storageKey]);

    useEffect(() => {
        if (!mappingFromSessionStorage) {
            (async () => {
                showAppSpinner();
                const csvFileHeaders = Array.isArray(csvFile) && csvFile.length === 2 && csvFile[1];
                try {
                    const initialMapping = await fetchLearnedMapping({ groupId, csvFileHeaders });
                    storeMapping(initialMapping);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
                hideAppSpinner();
            })();
        } else {
            setMappingState(mappingFromSessionStorage);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    return [mappingState, storeMapping];
};

export default useLocalMapping;
