import React from 'react';
import PropTypes from 'prop-types';
import RequiredAsterisk from 'react-rainbow-components/components/RequiredAsterisk';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    ApplicationContainer,
    FieldChip,
    RequiredContainer,
    RequiredIfEmptyType,
} from './styled';

const theme = {
    rainbow: {
        palette: {
            brand: '#D8F2EE',
        },
    },
};

const ObjectFieldChip = (props) => {
    const {
        className,
        style,
        isRequired,
        requiredIfEmpty,
        label,
        isChild,
    } = props;
    const showRequiredIfEmpty = Array.isArray(requiredIfEmpty) && requiredIfEmpty.length > 0;

    return (
        <ApplicationContainer className={className} style={style} theme={theme} isChild={isChild}>
            <RequiredContainer>
                <RequiredAsterisk required={!!isRequired} />
            </RequiredContainer>
            <RenderIf isTrue={showRequiredIfEmpty}>
                <RequiredIfEmptyType />
            </RenderIf>
            <FieldChip
                isRequired={isRequired}
                showRequiredIfEmpty={showRequiredIfEmpty}
                label={label}
                variant="brand"
            />
        </ApplicationContainer>
    );
};

ObjectFieldChip.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isRequired: PropTypes.bool,
    requiredIfEmpty: PropTypes.array,
    isChild: PropTypes.bool,
};

ObjectFieldChip.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    isRequired: false,
    requiredIfEmpty: undefined,
    isChild: false,
};

export default ObjectFieldChip;
