import shortid from 'shortid';

export default function getNormalizedRides({ keyField, rides }) {
    return rides.map((ride) => {
        const newRide = Object.keys(ride).reduce((acc, key) => {
            let newKey = key;
            const hasDot = key.indexOf('.') !== -1;
            if (hasDot) {
                newKey = key.replace('.', ' ');
            }
            acc[newKey] = ride[key];
            return acc;
        }, {});
        return {
            ...newRide,
            [keyField]: shortid.generate(),
        };
    });
}
