import isFilledArray from './isFilledArray';
import find from './find';

const errorsMap = {
    customerName: 'Assign a customer name to import the data successfully.',
    scheduledTime: 'Assign the pickup date and time of the ride to import the data successfully. I.e. 2020-12-25 10:30:00',
    pickupLocation: 'Assign a ride pickup location to import the data successfully.',
    'pickupLocation.address1': 'Assign the primary address of the ride pickup location to import the data successfully.',
    'pickupLocation.city': 'Assign the city of the ride pickup location to import the data successfully.',
    'pickupLocation.zip': 'Assign the zip code of the ride pickup location to import the data successfully.',
    'pickupLocation.state': 'Assign the state of the ride pickup location to import the data successfully.',
    destination: 'Assign the dropoff location to import the data successfully.',
    'destination.address1': 'Assign the primary address of the ride dropoff location to import the data successfully.',
    'destination.city': 'Assign the city of the ride dropoff location to import the data successfully.',
    'destination.zip': 'Assign the zip code of the ride dropoff location to import the data successfully.',
    'destination.state': 'Assign the state of the ride dropoff location to import the data successfully.',
};

const ADDRESS_ELEMENTS = 'address-elements';

const getFieldErrorValidation = (params) => {
    const {
        showErrors, fieldName, mapping, nestedFieldName,
    } = params;
    if (showErrors) {
        const fieldData = mapping[fieldName];
        if (fieldData.isRequired) {
            const shouldReturnUndefined = isFilledArray(fieldData.fromFields)
                || fieldData.typeSelected === ADDRESS_ELEMENTS || (typeof fieldData.fromFields === 'string' && !!fieldData.value);
            if (shouldReturnUndefined) {
                return undefined;
            }
            return errorsMap[fieldName] || errorsMap[nestedFieldName] || 'Assign a field required.';
        }
        if (isFilledArray(fieldData.requiredIfEmpty)) {
            const noFieldAssigned = !isFilledArray(fieldData.fromFields)
                && fieldData.requiredIfEmpty.every((value) => mapping[value]
                        && !isFilledArray(mapping[value].fromFields));
            if (noFieldAssigned) {
                return 'Assign a customer phone number to import the data successfully. I.e. +1 212 718 1234.';
            }
        }
    }
    return undefined;
};

function getFlatAddressType(params) {
    const {
        type, order, showErrors, parentFieldName,
    } = params;
    const addressElements = find((item) => item.type === ADDRESS_ELEMENTS, type);
    return Object.keys(addressElements.subFields).reduce((acc, fieldName) => {
        const fieldData = addressElements.subFields[fieldName];
        const { order: childOrder } = fieldData;
        const name = `${parentFieldName}.${fieldName}`;
        const error = getFieldErrorValidation({
            showErrors,
            fieldName,
            mapping: addressElements.subFields,
            nestedFieldName: name,
        });
        acc.push({
            ...fieldData,
            name,
            order: `${order}.${childOrder}`,
            error,
            isChild: true,
        });
        return acc;
    }, []);
}

const getMappingTableData = (mapping = {}, showErrors) => {
    if (mapping) {
        return Object.keys(mapping)
            .reduce((acc, fieldName) => {
                const fieldData = mapping[fieldName];
                const { type, typeSelected, order } = fieldData;
                const error = getFieldErrorValidation({ showErrors, fieldName, mapping });
                let hasNestedFields = false;

                if (Array.isArray(type)) {
                    if (typeSelected === ADDRESS_ELEMENTS) {
                        const nestedFields = getFlatAddressType({
                            type,
                            order,
                            showErrors,
                            parentFieldName: fieldName,
                        });
                        hasNestedFields = true;
                        // eslint-disable-next-line no-param-reassign
                        acc = acc.concat(nestedFields);
                    }
                }
                acc.push({
                    ...fieldData,
                    name: fieldName,
                    error,
                    isChild: false,
                    hasNestedFields,
                });
                return acc;
            }, [])
            .sort((a, b) => a.order - b.order);
    }
    return [];
};

export default getMappingTableData;
