import React from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    Container, Value, IconContainer, Content,
} from './styled';
import MultiTypePicker from './multiTypePicker';
import TextValue from './textValue';
import Icon from './icon';

const FieldTypeOutput = (props) => {
    const {
        value,
        row,
        onChangeFieldType,
        className,
        style,
    } = props;
    const isArrayType = Array.isArray(value);
    const addressTypeValue = {
        name: row.typeSelected,
        label: row.label,
    };

    const handleAddressTypeChange = (option) => {
        onChangeFieldType(row, option);
    };

    return (
        <Container
            className={className}
            style={style}
        >
            <Content>
                <IconContainer>
                    <Icon value={value} />
                </IconContainer>
                <Value>
                    <TextValue value={value} typeSelected={row.typeSelected} />
                </Value>
            </Content>
            <RenderIf isTrue={isArrayType}>
                <MultiTypePicker
                    options={value}
                    value={addressTypeValue}
                    onChange={handleAddressTypeChange}
                />
            </RenderIf>
        </Container>
    );
};

FieldTypeOutput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    row: PropTypes.object,
    onChangeFieldType: PropTypes.func,
};

FieldTypeOutput.defaultProps = {
    className: undefined,
    style: undefined,
    value: undefined,
    row: {},
    onChangeFieldType: () => {},
};

export default FieldTypeOutput;
