import { useState, useEffect } from 'react';
import {
    useParams,
} from 'react-router-dom';
import groupBy from 'lodash/groupBy';
import getServiceLevels from '../../services/firebase/getServiceLevels';

let services = [];
let isFetching = true;
let group = '';

function getNormalizedServices(serviceLevels) {
    const groupedServices = groupBy(serviceLevels, 'account.name');
    const arr = Object.keys(groupedServices)
        .sort((a, b) => {
            const aLowerCased = a.toLowerCase();
            const bLowerCased = b.toLowerCase();
            if (a === 'undefined') {
                return -1;
            }
            if (b === 'undefined') {
                return 1;
            }
            if (aLowerCased > bLowerCased) {
                return 1;
            }
            if (bLowerCased > aLowerCased) {
                return -1;
            }
            return 0;
        })
        .reduce((acc, key) => {
            const isUndefined = key === 'undefined';
            const fleetServiceKey = isUndefined ? 'Retail' : key;
            let newAcc = [...acc];

            if (!isUndefined) {
                newAcc = newAcc.concat(['divider']);
            }

            newAcc = newAcc.concat([{
                key: fleetServiceKey,
                name: fleetServiceKey,
                label: fleetServiceKey,
                variant: 'header',
            }]);

            newAcc = newAcc.concat(groupedServices[key].map((serviceLevel) => {
                const { id, name } = serviceLevel;
                return {
                    key: id,
                    name: id,
                    label: name,
                };
            }));
            return newAcc;
        }, []);
    return arr;
}

export default function useServiceLevels() {
    const { groupId } = useParams();
    const [serviceLevels, setServiceLevels] = useState(services);
    const [isLoading, setLoading] = useState(isFetching);
    useEffect(() => {
        (async () => {
            if (services.length === 0 || groupId !== group) {
                const serviceLevelsResponse = await getServiceLevels(groupId);
                if (serviceLevelsResponse && serviceLevelsResponse.data) {
                    const normalizedServices = getNormalizedServices(serviceLevelsResponse.data);
                    services = normalizedServices;
                    setServiceLevels(normalizedServices);
                }
                isFetching = false;
                group = groupId;
                setLoading(false);
            }
        })();
    }, [groupId]);
    return [serviceLevels, isLoading];
}
