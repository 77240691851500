import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h1`
    font-size: 22px;
    letter-spacing: 0.5;
    color: #616175;
    font-weight: 400;
`;

export const BackButton = styled.a`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-right: 20px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;

export const SectionContainer = styled.section`
    width: 100%;
    padding: 12px 44px 0 44px;
`;

export const SectionHeader = styled.span`
    display: flex;
    align-items: center;
`;

export const SectionHeaderTitle = styled.span`
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    display: inline-block;
    margin-left: 8px;
    font-weight: 200;
`;

export const SectionHeaderTitleBold = styled(SectionHeaderTitle)`
    font-weight: 900;
`;

export const SectionHeaderSubTitle = styled.span`
    font-size: 16px;
    margin-top: 2px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    display: inline-block;
`;

export const SectionHeaderSubTitleBold = styled(SectionHeaderSubTitle)`
    font-weight: 900;
`;
