import React from 'react';
import PropTypes from 'prop-types';

export default function Unknown(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="21px" height="20px" viewBox="0 0 21 20">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="unknown" fillRule="nonzero">
                    <path d="M10.0390625,20 C15.546875,20 20.0390625,15.5078125 20.0390625,10 C20.0390625,4.4921875 15.546875,0 10.0390625,0 C4.53125,0 0.0390625,4.4921875 0.0390625,10 C0.0390625,15.5078125 4.53125,20 10.0390625,20 Z" id="Path" fill="#F4F6F9" />
                    <path d="M10.0207776,14.8845971 C10.5332597,14.8845971 10.9501896,15.3014922 10.9501896,15.8140091 C10.9501896,16.3263891 10.5331576,16.7434211 10.0207776,16.7434211 C9.50839765,16.7434211 9.09136566,16.3263891 9.09136566,15.8140091 C9.09136566,15.3014922 9.50829556,14.8845971 10.0207776,14.8845971 Z M10.0207776,3.42105263 C12.3539072,3.42105263 14.2518426,5.31914156 14.2518426,7.65235659 C14.2518426,9.33649424 13.2546937,10.8545062 11.7074759,11.5337116 L11.6604226,11.5540361 C11.0681211,11.8056085 10.7865338,12.2480672 10.7855889,12.9379462 L10.7857159,13.3662065 C10.7857159,13.7885407 10.4432042,14.1311448 10.0207776,14.1311448 C9.60465592,14.1311448 9.26608138,13.7986914 9.25606738,13.3850629 L9.25584684,12.9749729 C9.24293263,11.6703895 9.87495022,10.6652444 11.0268881,10.1611808 L11.0619543,10.1460597 C12.0706847,9.71720771 12.721966,8.7385959 12.721966,7.65235659 C12.721966,6.16293935 11.510096,4.95092922 10.0207776,4.95092922 C8.54630353,4.95092922 7.34361919,6.13886882 7.31971295,7.60775968 L7.31935027,7.65235659 C7.31935027,8.07485642 6.9769118,8.41729488 6.55441198,8.41729488 C6.13191211,8.41729488 5.78947368,8.07485643 5.78947368,7.65235659 C5.78947368,5.31910317 7.68744743,3.42105263 10.0207776,3.42105263 Z" id="Combined-Shape" fill="#616175" />
                </g>
            </g>
        </svg>
    );
}

Unknown.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Unknown.defaultProps = {
    style: {},
    className: undefined,
};
