import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'react-rainbow-components';
import asColumn from '../../../hooks/useAsColumn';
import ObjectFieldChip from '../../../components/ObjectFieldChip';
import FieldTypeOutput from '../../../components/FieldTypeOutput';
import AssignSelector from '../../../components/AssignSelector';
import { MapTable } from './styled';

// eslint-disable-next-line react/jsx-props-no-spreading
const ObjectFieldChipColumnAdapter = ({ row }) => <ObjectFieldChip {...row} />;

ObjectFieldChipColumnAdapter.propTypes = {
    row: PropTypes.object,
};

ObjectFieldChipColumnAdapter.defaultProps = {
    row: {},
};

const MappingTable = (props) => {
    const {
        tableData,
        onChangeField,
        onChangeFieldType,
    } = props;

    return (
        <MapTable
            className="rainbow-m-bottom_large rainbow-m-top_small"
            keyField="name"
            data={tableData}
            showRowNumberColumn
            id="mapping-table"
        >
            <Column
                header="Evertransit Fields"
                defaultWidth={280}
                component={asColumn(ObjectFieldChipColumnAdapter)}
            />
            <Column
                header="Type"
                defaultWidth={220}
                field="type"
                component={({ value, row }) => (
                    <FieldTypeOutput
                        value={value}
                        row={row}
                        onChangeFieldType={onChangeFieldType}
                    />
                )}
            />
            <Column
                defaultWidth={360}
                header="Your Fields"
                field="fromFields"
                component={({ value, row }) => (
                    <AssignSelector
                        value={value}
                        row={row}
                        onChange={onChangeField}
                    />
                )}
            />
            <Column
                header="Description"
                field="description"
            />
        </MapTable>
    );
};

MappingTable.propTypes = {
    tableData: PropTypes.array,
    onChangeField: PropTypes.func,
    onChangeFieldType: PropTypes.func,
};

MappingTable.defaultProps = {
    tableData: [],
    onChangeField: () => {},
    onChangeFieldType: () => {},
};

export default MappingTable;
