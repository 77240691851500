import React from 'react';
import PropTypes from 'prop-types';

export default function ClockFilled(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="32px" height="32px" viewBox="0 0 32 32">
            <g id="data-app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-history" transform="translate(-33.000000, -306.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M49,306 C57.8224,306 65,313.1776 65,322 C65,330.8224 57.8224,338 49,338 C40.1776,338 33,330.8224 33,322 C33,313.1776 40.1776,306 49,306 Z M48.8333333,311.9 C48.373021,311.9 48,312.272941 48,312.733333 L48,312.733333 L48,322.834333 C48,323.294725 48.373021,323.667667 48.8333333,323.667667 L48.8333333,323.667667 L55.3666667,323.667667 C55.826979,323.667667 56.2,323.294725 56.2,322.834333 C56.2,322.373941 55.826979,322.001 55.3666667,322.001 L55.3666667,322.001 L49.6653333,322 L49.6666667,312.733333 C49.6666667,312.356444 49.4152469,312.033248 49.0643211,311.932425 L49.0643211,311.932425 L48.9415511,311.907398 Z" id="clock-filled" />
                </g>
            </g>
        </svg>
    );
}

ClockFilled.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

ClockFilled.defaultProps = {
    style: {},
    className: undefined,
};
