import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import OutputColumn from '../../../components/OutputColumn';
import Notification from '../../../components/Notification';
import useExportDoc from '../../../hooks/useExportDoc';
import {
    Container,
    StartButton,
    Content,
    Card,
} from './styled';

export default function Done({ location }) {
    const { state } = location;
    const { groupId } = useParams();
    const exportItem = useExportDoc(state && state.exportId);

    if (state && typeof state.total === 'number') {
        const { total } = state;
        return (
            <Container>
                <Content>
                    <Card
                        title="Export Completed"
                        description="Your report has been successfully exported."
                        footer={(
                            <OutputColumn
                                label="Total"
                                value={total}
                                id="total"
                            />
                        )}
                    />
                    <StartButton data-cy="start-import-link" className="rainbow-m-vertical_large" to={`/app/${groupId}/export`}>
                        Start a new Export?
                    </StartButton>
                </Content>
                <Notification data={exportItem} exportId={state.exportId} />
            </Container>
        );
    }
    return <Redirect to={`/app/${groupId}/export`} />;
}

Done.propTypes = {
    location: PropTypes.object.isRequired,
};
