import React from 'react';
import PropTypes from 'prop-types';
import { SideBarOption } from '@rainbow-modules/app';
import { useRouteMatch } from 'react-router-dom';
import Export from '../../components/icons/Export';
import ExportFilled from '../../components/icons/ExportFilled';
import ImportFilled from '../../components/icons/ImportFilled';
import ClockFilled from '../../components/icons/ClockFilled';
import Clock from '../../components/icons/Clock';
import {
    Container,
    AppLogo,
    SidebarIcon,
    Bar,
} from './styled';

function getGroupId(match) {
    if (match && match.params && match.params.groupId) {
        return match.params.groupId;
    }
    return '';
}

export default function AppSidebar(props) {
    const {
        className,
        style,
        path,
    } = props;
    const match = useRouteMatch(path);
    const groupId = getGroupId(match);

    return (
        <Container className={className} style={style}>
            <AppLogo />
            <Bar className="rainbow-p-vertical_x-large">
                <SideBarOption
                    icon={<SidebarIcon />}
                    selectedIcon={<SidebarIcon as={ImportFilled} />}
                    label="Import"
                    name="Import"
                    path={`/app/${groupId}/import`}
                    className="rainbow-m-bottom_x-large"
                />
                <SideBarOption
                    icon={<SidebarIcon as={Export} />}
                    selectedIcon={<SidebarIcon as={ExportFilled} />}
                    label="Export"
                    name="Export"
                    path={`/app/${groupId}/export`}
                    className="rainbow-m-bottom_x-large"
                />
                <SideBarOption
                    icon={<SidebarIcon as={Clock} />}
                    selectedIcon={<SidebarIcon as={ClockFilled} />}
                    label="History"
                    name="History"
                    path={`/app/${groupId}/history`}
                />
            </Bar>
        </Container>
    );
}

AppSidebar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    path: PropTypes.string,
};

AppSidebar.defaultProps = {
    className: undefined,
    style: undefined,
    path: '/app/:groupId',
};
