import React from 'react';
import PropTypes from 'prop-types';

const OptionList = ({ list }) => list.map((option, index) => {
    const key = `option_${index}`;
    return (
        <option key={key} value={option}>
            {option}
        </option>
    );
});

OptionList.propTypes = {
    list: PropTypes.array,
};

OptionList.defaultProps = {
    list: [],
};

export default OptionList;
