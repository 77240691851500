import styled from 'styled-components';
import { FirestoreTable } from '@rainbow-modules/listview';
import Download from '../../../components/icons/Download';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 24px;
`;

export const DownloadIcon = styled(Download)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const HistoryTable = styled(FirestoreTable)`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.h1`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    align-self: flex-start;
    margin-left: 48px;
    margin-bottom: 16px;
`;
