import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import getExportDownloadUrl from '../../../services/storage/getExportDownloadUrl';
import {
    DownloadIcon,
} from '../styled';

const downloadExport = async (exportId) => {
    const downloadUrl = await getExportDownloadUrl(exportId);
    window.location.href = downloadUrl;
};

const DownloadButton = ({ row }) => {
    const { id: exportId } = row;

    return (
        <Button className="rainbow-m-vertical_xx-small" onClick={() => downloadExport(exportId)}>
            <DownloadIcon className="rainbow-m-right_x-small" />
            Download
        </Button>
    );
};

DownloadButton.propTypes = {
    row: PropTypes.object,
};

DownloadButton.defaultProps = {
    row: {},
};

export default DownloadButton;
