export default function getFormattedRangeDate(dates) {
    if (Array.isArray(dates)) {
        const startDay = new Intl.DateTimeFormat('en-US').format(dates[0]);
        if (dates.length > 1) {
            const endDay = new Intl.DateTimeFormat('en-US').format(dates[1]);
            return `${startDay} - ${endDay}`;
        }
        return startDay;
    }
    return '';
}
