import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getExportDownloadUrl from '../../services/storage/getExportDownloadUrl';
import { DescriptionContainer, DownloadIcon, Link } from './styled';

function useExportDownloadUrl(exportId, status) {
    const [exportUrl, setExportUrl] = useState('');
    useEffect(() => {
        if (exportId && status === 'completed') {
            (async () => {
                const downloadUrl = await getExportDownloadUrl(exportId);
                setExportUrl(downloadUrl);
            })();
        }
    }, [status, exportId]);
    return exportUrl;
}

const FORMATS = {
    small: { year: '2-digit', month: 'numeric', day: 'numeric' },
    medium: { year: 'numeric', month: '2-digit', day: '2-digit' },
    large: {
        weekday: 'long', year: 'numeric', month: '2-digit', day: '2-digit',
    },
};

function getFormattedDate(date, formatStyle = 'medium', locale = 'en-US') {
    if (date) {
        try {
            const options = FORMATS[formatStyle] || FORMATS.medium;
            const value = typeof date === 'string' ? new Date(date) : date;
            return new Intl.DateTimeFormat(locale, options).format(value);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            return '';
        }
    }
    return '';
}

export default function Description(props) {
    const {
        status,
        startDate,
        endDate,
        exportId,
    } = props;
    const dateRange = `${getFormattedDate(startDate.toDate())} - ${getFormattedDate(endDate.toDate())}`;
    const downloadUrl = useExportDownloadUrl(exportId, status);

    if (status === 'completed') {
        return (
            <DescriptionContainer>
                {`"Export Rides ${dateRange}" is ready to download.`}
                <Link href={downloadUrl}>
                    <DownloadIcon />
                    Download File
                </Link>
            </DescriptionContainer>
        );
    }
    if (status === 'failed') {
        return <DescriptionContainer>{`"Export Rides ${dateRange}" has failed to process file."`}</DescriptionContainer>;
    }
    return <DescriptionContainer>{`"Export Rides ${dateRange}" it is being processed, you can download this when it is finished."`}</DescriptionContainer>;
}

Description.propTypes = {
    status: PropTypes.string.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    exportId: PropTypes.string.isRequired,
};
