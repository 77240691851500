import styled from 'styled-components';
import { Picklist, Table } from 'react-rainbow-components';
import { Link } from 'react-router-dom';
import Trash from '../../../../components/icons/Trash';

export const Container = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const TemplateSelector = styled(Picklist)`
    width: 220px;
`;

export const BackButton = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-right: 20px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;

export const TableContainer = styled.div`
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    margin: 12px 0 20px 0;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const ExportTable = styled(Table)`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const TrashIcon = styled(Trash)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;
