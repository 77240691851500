import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, HelpText } from 'react-rainbow-components';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import {
    Container,
    Input,
    Placeholder,
    Cell,
    Value,
    PencilIcon,
} from './styled';

const ESC_KEYCODE = 27;
const ENTER_KEYCODE = 13;

const InlineEditField = (props) => {
    const {
        value,
        row,
        onChange,
        className,
        style,
    } = props;
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setValue] = useState(value);
    const inputRef = useRef();
    const buttonRef = useRef();

    useEffect(() => {
        if (showInput) {
            inputRef.current.focus();
        }
    }, [showInput]);

    const changeValue = () => {
        setShowInput(false);
        onChange(inputValue, row);
    };

    const focusButton = () => {
        setTimeout(() => buttonRef.current.focus(), 0);
    };

    const handleInputKeyDown = (event) => {
        if (event.keyCode === ESC_KEYCODE) {
            focusButton();
            setValue(value);
            setShowInput(false);
        }
        if (event.keyCode === ENTER_KEYCODE) {
            changeValue();
        }
    };

    return (
        <Container
            className={className}
            style={style}
            data-cy="inline-edit-cell"
        >
            <Cell>
                <RenderIf isTrue={!!row.error}>
                    <HelpText
                        variant="error"
                        title="This field needs to be filled"
                        text={row.error}
                    />
                </RenderIf>
                <RenderIf isTrue={!showInput}>
                    <RenderIf isTrue={!value}>
                        <Placeholder>
                            None
                        </Placeholder>
                    </RenderIf>
                    <RenderIf isTrue={!!value}>
                        <Value>
                            {value}
                        </Value>
                    </RenderIf>
                    <ButtonIcon
                        className="rainbow-m-left_medium"
                        icon={<PencilIcon />}
                        onClick={() => setShowInput(true)}
                        ref={buttonRef}
                    />
                </RenderIf>
                <RenderIf isTrue={showInput}>
                    <Input
                        onBlur={changeValue}
                        onChange={(event) => setValue(event.target.value)}
                        onKeyDown={handleInputKeyDown}
                        value={inputValue}
                        ref={inputRef}
                    />
                </RenderIf>
            </Cell>
        </Container>
    );
};

InlineEditField.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    row: PropTypes.object,
};

InlineEditField.defaultProps = {
    className: undefined,
    style: undefined,
    value: undefined,
    onChange: () => {},
    row: {},
};

export default InlineEditField;
