import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { Application } from 'react-rainbow-components';
import { chipsTheme } from '../../theme';
import Plus from '../icons/Plus';
import ChipsList from './ChipList';
import SelectOptions from './SelectOptions';
import {
    Container,
    Placeholder,
    Cell,
    PlusButton,
    ChipListContainer,
    StyledErrorMessage,
} from './styled';

const getParsedValue = (value) => {
    const isDefined = !!value;
    if (isDefined) {
        if (value instanceof Array) {
            return value;
        }
        return [value];
    }
    return [];
};

function getSelectOptions(options, value) {
    return options.filter((option) => value.indexOf(option) === -1);
}

const AssignField = (props) => {
    const {
        value: rawValue,
        onChange,
        options,
        variant,
        error,
        isSingleService,
        className,
        style,
    } = props;
    const [showSelect, setShowSelect] = useState(false);
    const value = getParsedValue(rawValue);

    const hideSelect = () => {
        if (!isSingleService) {
            setShowSelect(false);
        }
    };

    const onChangeSelect = (selected) => {
        const newField = selected
            && selected.target
            && selected.target.value;
        if (isSingleService) {
            onChange(selected);
        } else {
            onChange([...value, newField]);
        }
        hideSelect();
    };

    const hasValue = value.length > 0;

    return (
        <Container className={className} style={style} error={!!error} data-cy="assign-field">
            <Cell onBlur={hideSelect} error={!!error}>
                <RenderIf isTrue={!showSelect}>
                    <RenderIf isTrue={!hasValue}>
                        <Placeholder>None</Placeholder>
                    </RenderIf>
                    <RenderIf isTrue={hasValue}>
                        <Application theme={chipsTheme}>
                            <ChipListContainer>
                                <ChipsList list={value} variant={variant} onChange={onChange} />
                            </ChipListContainer>
                        </Application>
                    </RenderIf>
                    <PlusButton
                        variant="neutral"
                        icon={<Plus />}
                        size="x-small"
                        onClick={() => setShowSelect(true)}
                        id="assign-field-button"
                    />
                    <RenderIf isTrue={!!error}>
                        <StyledErrorMessage
                            error={error}
                            dataCy="field-error-message"
                        />
                    </RenderIf>
                </RenderIf>
                <RenderIf isTrue={showSelect}>
                    <SelectOptions
                        isSingleService={isSingleService}
                        onChange={onChangeSelect}
                        options={getSelectOptions(options, value)}
                    />
                </RenderIf>
            </Cell>
        </Container>
    );
};

AssignField.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func,
    options: PropTypes.array,
    variant: PropTypes.oneOf(['base', 'neutral', 'outline-brand', 'brand']),
    error: PropTypes.string,
    isSingleService: PropTypes.bool,
};

AssignField.defaultProps = {
    className: undefined,
    style: undefined,
    value: [],
    onChange: () => {},
    options: [],
    variant: 'base',
    error: undefined,
    isSingleService: false,
};

export default AssignField;
