import styled from 'styled-components';
import { Picklist } from 'react-rainbow-components';
import ErrorMessage from '../../ErrorMessage';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const ServiceSelector = styled(Picklist)`
    width: 220px;
    margin-right: 12px;
`;

export const ErrorIcon = styled(ErrorMessage)`
    position: relative;
    margin-right: 12px;
`;
