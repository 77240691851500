const useLocalData = (key) => {
    const data = sessionStorage.getItem(key);
    if (typeof data === 'string') {
        try {
            return JSON.parse(data);
        } catch (err) {
            return data;
        }
    }
    return undefined;
};

export default useLocalData;
