import React from 'react';
import PropTypes from 'prop-types';

export default function App(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="30px" height="30px" viewBox="0 0 30 30">
            <g id="app-import-export" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="import-export-/-import-2" transform="translate(-1279.000000, -27.000000)" fill="currentColor">
                    <path d="M1282.75,49.5 C1284.82107,49.5 1286.5,51.1789322 1286.5,53.25 C1286.5,55.3210678 1284.82107,57 1282.75,57 C1280.67893,57 1279,55.3210678 1279,53.25 C1279,51.1789322 1280.67893,49.5 1282.75,49.5 Z M1294,49.5 C1296.07107,49.5 1297.75,51.1789322 1297.75,53.25 C1297.75,55.3210678 1296.07107,57 1294,57 C1291.92893,57 1290.25,55.3210678 1290.25,53.25 C1290.25,51.1789322 1291.92893,49.5 1294,49.5 Z M1305.25,49.5 C1307.32107,49.5 1309,51.1789322 1309,53.25 C1309,55.3210678 1307.32107,57 1305.25,57 C1303.17893,57 1301.5,55.3210678 1301.5,53.25 C1301.5,51.1789322 1303.17893,49.5 1305.25,49.5 Z M1305.25,38.25 C1307.32107,38.25 1309,39.9289322 1309,42 C1309,44.0710678 1307.32107,45.75 1305.25,45.75 C1303.17893,45.75 1301.5,44.0710678 1301.5,42 C1301.5,39.9289322 1303.17893,38.25 1305.25,38.25 Z M1294,38.25 C1296.07107,38.25 1297.75,39.9289322 1297.75,42 C1297.75,44.0710678 1296.07107,45.75 1294,45.75 C1291.92893,45.75 1290.25,44.0710678 1290.25,42 C1290.25,39.9289322 1291.92893,38.25 1294,38.25 Z M1282.75,38.25 C1284.82107,38.25 1286.5,39.9289322 1286.5,42 C1286.5,44.0710678 1284.82107,45.75 1282.75,45.75 C1280.67893,45.75 1279,44.0710678 1279,42 C1279,39.9289322 1280.67893,38.25 1282.75,38.25 Z M1282.75,27 C1284.82107,27 1286.5,28.6789322 1286.5,30.75 C1286.5,32.8210678 1284.82107,34.5 1282.75,34.5 C1280.67893,34.5 1279,32.8210678 1279,30.75 C1279,28.6789322 1280.67893,27 1282.75,27 Z M1294,27 C1296.07107,27 1297.75,28.6789322 1297.75,30.75 C1297.75,32.8210678 1296.07107,34.5 1294,34.5 C1291.92893,34.5 1290.25,32.8210678 1290.25,30.75 C1290.25,28.6789322 1291.92893,27 1294,27 Z M1305.25,27 C1307.32107,27 1309,28.6789322 1309,30.75 C1309,32.8210678 1307.32107,34.5 1305.25,34.5 C1303.17893,34.5 1301.5,32.8210678 1301.5,30.75 C1301.5,28.6789322 1303.17893,27 1305.25,27 Z" id="app" />
                </g>
            </g>
        </svg>
    );
}

App.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

App.defaultProps = {
    style: {},
    className: undefined,
};
