import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

function isValidPhoneNumber(phoneNumber, region = 'US') {
    if (phoneNumber) {
        try {
            return phoneUtil.isValidNumber(
                phoneUtil.parseAndKeepRawInput(phoneNumber, region),
            );
        } catch (error) {
            return false;
        }
    }
    return false;
}

export default isValidPhoneNumber;
