import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-rainbow-components';

export default function FieldOptions({ options, onAddRow }) {
    return options.map((field) => {
        const { evertransitField } = field;
        return (
            <MenuItem
                key={evertransitField}
                label={evertransitField}
                onClick={() => onAddRow(field)}
            />
        );
    });
}

FieldOptions.propTypes = {
    options: PropTypes.array,
    onAddRow: PropTypes.func,
};

FieldOptions.defaultProps = {
    options: [],
    onAddRow: () => {},
};
