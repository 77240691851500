import React from 'react';
import PropTypes from 'prop-types';
import {
    ButtonMenu,
} from 'react-rainbow-components';
import ImportExportLogo from '../icons/ImportExportLogo';
import {
    AppIcon, MenuContainer, Item, SelectAppIcon, ItemLabel, StyledMenuItem,
} from './styled';

const dispatchUrl = process.env.REACT_APP_DISPATCH_URL;

export default function AppsMenu(props) {
    const {
        className,
        style,
    } = props;

    return (
        <ButtonMenu
            icon={<AppIcon />}
            buttonSize="large"
            buttonVariant="base"
            menuAlignment="right"
            menuSize="small"
            className={className}
            style={style}
            id="apps-menu"
        >
            <MenuContainer>
                <StyledMenuItem label={(
                    <Item href={window.location.href}>
                        <SelectAppIcon as={ImportExportLogo} />
                        <ItemLabel>
                            Import/Export
                        </ItemLabel>
                    </Item>
                )}
                />
                <StyledMenuItem label={(
                    <Item
                        href={dispatchUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <SelectAppIcon />
                        <ItemLabel>
                            ET Dispatch
                        </ItemLabel>
                    </Item>
                )}
                />
            </MenuContainer>
        </ButtonMenu>
    );
}

AppsMenu.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

AppsMenu.defaultProps = {
    className: undefined,
    style: undefined,
};
